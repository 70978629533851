import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Barcode, BarcodePicker, ScanResult, ScanSettings, SingleImageModeSettings } from 'scandit-sdk';
import { CoreService } from '../services/core.service';


declare var $;

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {

  public videoFitCover: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.COVER;
  public videoFit: BarcodePicker.ObjectFit = this.videoFitCover;
  public scannerGuiStyle: BarcodePicker.GuiStyle = BarcodePicker.GuiStyle.VIEWFINDER;
  public scannedCodes: Barcode[] = [];
  public scannedBarcodes: Barcode[] = [];
  public settingsQR: ScanSettings;
  public settings128: ScanSettings;
  public activeSettingsQR: ScanSettings;
  public activeSettingsBarcode: ScanSettings;

  public singleImageSettings: SingleImageModeSettings;

  isCollapsed = false;
  step = 1

  selectedPayment = null

  productList: any = [];
  productDetails;
  product;
  // cardNumber = localStorage.getItem('virtualOrCardId')
  cardNumber = localStorage.getItem("virtualOrCardId")
  basketID;
  totalPrice;
  totalDiscount;
  totalQTY;

  storeName;
  storeID;

  notifyState = false;
  notifyStateAdd = false;
  notifyStateDelete = false;

  hasValueableProduct = false;

  showJetKasa = 0;
  showMoneyPay = 0;
  showCreditCard = 0;

  basketDate;

  reDataList

  // @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
  //   if (this.step == 2 && (this.showMoneyPay || this.showCreditCard)) {
  //     localStorage.setItem("step", "2")
  //   }
  // }

  constructor(public coreService: CoreService, private router: Router) {



    this.settingsQR = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.QR],
      codeDuplicateFilter: 2000
    });

    this.settings128 = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.CODE128, Barcode.Symbology.EAN13, Barcode.Symbology.EAN8, Barcode.Symbology.CODE39, Barcode.Symbology.CODE93, Barcode.Symbology.PDF417, Barcode.Symbology.AZTEC],
      codeDuplicateFilter: 2000
    });

    this.activeSettingsQR = this.settingsQR;
    this.activeSettingsBarcode = this.settings128

    this.singleImageSettings = {
      desktop: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
      mobile: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
    };
  }

  async ngOnInit() {

    //Product List
    this.storeName = localStorage.getItem('storeName')
    this.storeID = localStorage.getItem('storeId')
    this.basketID = localStorage.getItem('basketId')
    this.basketDate = new Date(localStorage.getItem('basketDate'))

    if (this.basketID) {
      await this.getBasket()
      await this.getPaymentOptions()
    }
    else if (this.basketID == null) {
      this.basketID = ""
    }

    


  } 

  async getBasket() {
    var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productList = data["resultData"]["productList"]
      console.log(this.productList)
      this.productDetails = data["resultData"]
      for (let index = 0; index < this.productList.length; index++) {
        this.totalQTY = parseInt(this.productList[index]["quantity"])
        if (this.productList[index].isValuable == 1) {
          this.hasValueableProduct = true
        }
        this.productList[index].calcPrice = parseFloat(this.productList[index].calcPrice)
      }
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["basketAmount"])
      
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      console.log(this.totalPrice)
      console.log(this.totalDiscount)
    }, (err => {
      console.log(err)
    }))
  }

  async addProductToBasket(product) {
    this.product = product

    var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${product?.scannedBarcode}&originalBarcode=${product?.originalBarcode}&productCode=${product?.productCode}&quantity=${parseInt(product?.quantity) + 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`
    console.log(data)
    this.coreService.coreServiceFunc(data).subscribe(async data => {

      await this.getBasket()

      this.coreService.testRXJS(this.productList)
      this.notifyStateDelete = false;
      this.notifyState = true
      this.notifyStateAdd = true

      setTimeout(() => {
        this.notifyStateAdd = false;
        this.notifyState = false
      }, 3000);

      console.log(data)

    })


  }

  async deleteProductToBasket(product) {
    this.product = product
    if (parseFloat(product.quantity) > 1) {
      var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${product?.scannedBarcode}&originalBarcode=${product?.originalBarcode}&productCode=${product?.productCode}&quantity=${parseInt(product?.quantity) - 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(async data => {

        await this.getBasket()

        this.coreService.testRXJS(this.productList)
        this.notifyStateAdd = false;
        this.notifyState = true
        this.notifyStateDelete = true

        setTimeout(() => {
          this.notifyState = false
          this.notifyStateDelete = false;
        }, 3000);
        console.log(data)

      })
    }
    else if (parseFloat(product.quantity) == 1 || parseFloat(product.quantity) < 1) {
      this.router.navigateByUrl(`/scan-delete-product/${product?.scannedBarcode}`) 
      // var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&scannedBarcode=${this.barcodeID}&originalBarcode=${this.barcodeID}&productCode=${this.productDetails["productCode"]}&quantity=${parseInt(this.productDetails.quantity) + 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      // this.coreService.coreServiceFunc(data).subscribe(data => {

      //   this.productDetails = data["resultData"]["productList"][0]
      //   this.productDetails.isInBasket = 1
      //   this.isAddedBasket = 1
      //   console.log(data)

      // })
    }

  }

  async getPaymentOptions() {
    var data = `act=mkolaypaymentoptions&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457`

    this.coreService.coreServiceFunc(data).subscribe(async data => {
      console.log(data)
      console.log(this.hasValueableProduct)
      var array: any = data;
      var reData = {
        jetKasa: {},
        moneyPay: {},
        creditCard: {}
      }

      for (let index = 0; index < array?.length; index++) {
        if (array[index].type == "JETKASA") {
          reData.jetKasa = array[index]
        }
        if (array[index].type == "MONEYPAY") {
          reData.moneyPay = array[index]
        }
        if (array[index].type == "CREDITCARD") {
          reData.creditCard = array[index]
        }
      }


      
      if (this.hasValueableProduct && reData.jetKasa["isEnabled"] == 1) {
        console.log("girdi 1")
        this.showJetKasa = reData.jetKasa["isEnabled"]
        var paymentOptionList = {
          "jetKasa" : this.showJetKasa,
          "moneyPay" : this.showMoneyPay,
          "creditCard":this.showCreditCard
        }
        localStorage.setItem("paymentOptionList" , JSON.stringify(paymentOptionList))

        return 
      }
      else if (this.hasValueableProduct && reData.jetKasa["isEnabled"] == 0) {
        this.showJetKasa = 0
      }

      else if (!this.hasValueableProduct) {
        this.showJetKasa = reData.jetKasa["isEnabled"]
        this.showMoneyPay = reData.moneyPay["isEnabled"]
        this.showCreditCard = reData.creditCard["isEnabled"]
      }
      
      console.log("JETKASA: " + this.showJetKasa)
      console.log("MONEYPAY: " + this.showMoneyPay)
      console.log("CREDITCARD: " + this.showCreditCard)

     
   })
  }

  goStore() {
    if (this.storeID) {
      this.router.navigateByUrl(`/store/${this.storeID}`);
    }
    else {
      this.router.navigateByUrl(`/check-location`);
    }
  }

  onScan(result: ScanResult) {
    if (this.scannedCodes.length == 0) {
      this.scannedCodes = this.scannedCodes.concat(result.barcodes);
      console.log(this.scannedCodes[0].data)

    }
  }

  // redirectSelectedPayment() {
  //   console.log(this.selectedPayment)

  //   if (this.selectedPayment == 1) {
  //     location.href = "/payment/jetkasa/scan-qr"

  //   }
  //   else if (this.selectedPayment == 2) {
    
  //     localStorage.setItem("paymentFlow" ,this.selectedPayment)
  //     if (!localStorage.getItem("userInfo")) {
  //       console.log("girdi")
  //       $('#moneyLoginModal').modal('show')
       
  //     }
  //     else {
  //       location.href = "/payment/money-pay/scan-qr"
  //     }
  //   }
  //   else if (this.selectedPayment == 3) {
      
  //     localStorage.setItem("paymentFlow" , this.selectedPayment)
  //     if (!localStorage.getItem("userInfo")) {
       
  //       $('#moneyLoginModal').modal('show')
  //     }
  //     else {
  //       location.href = "/payment/credit-card/scan-qr"
  //     }
  //   }

  // }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  checkStart() {
    if (localStorage.getItem("basketId")) {
      this.router.navigateByUrl("/scan-add-product")
    }
    else {
      this.router.navigateByUrl("/search")
    }
  }

  goPaymentStep(){
    if(this.showMoneyPay || this.showCreditCard){
      location.href = "/payment/payment-options"
    }
    else if(!this.showMoneyPay && !this.showCreditCard && this.showJetKasa)
      this.router.navigateByUrl("/payment/jetkasa/scan-qr")
    }

}
