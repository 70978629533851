<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/search" style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Mağaza Seç</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map" class="map rounded-2xl" #mapElement></div>

<div
    style="height: 50%;background: white;border-top-left-radius: 33px;border-top-right-radius: 33px;position: fixed;bottom: 0;width: 100%;box-shadow: 0px -4px 8px #0000001a;">
    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 20px;" class="col-12">
                <div routerLink="/search"
                    style="height: 48px;width: 100%;background-color: #F7F7F7; border-radius: 8px;display: flex;align-items: center;">
                    <img style="width: 30px;margin-left: 10px;margin-right: 10px;"
                        src="../../assets/img/search-icon.png">
                    <span class="c-color6" style="font-size: 16px;font-weight: 400;">Arama Yaparak Bul</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div style="margin-top: 15px;" class="col-12">
                <div class="bg-color3" style="width: 100%; border-radius: 8px;">
                    <div class="container-fluid">
                        <div class="row">
                            <div style="margin-top: 12px;" class="col-12">
                                <h5 class="c-color1" style="font-size: 14px;font-weight: 400;text-align: center;">Size En
                                    Yakın Migros</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div style="display: inline-flex;align-items: center;">
                                    <img style="width: 40px;margin-right: 4px;"
                                        src="../../assets/img/migros-pin-icon.png">
                                    <div>
                                        <h5 class="c-color1"
                                            style="font-size: 16px;font-weight: 500;text-align: left;margin-bottom: 0;">
                                            {{nearStore?.MagazaAdi}}</h5>
                                        <span class="c-color4" style="font-size: 12px;font-weight: 300;">{{nearStore?.DistanceKM | number : '1.2-2'}} km</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button routerLink="/store/{{nearStore?.MagazaNo}}"
                                    style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 20px;margin-bottom: 12px;"
                                    type="button" class="btn btn-primary bg-color1  c-color8">Mağazayı Seç</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <button routerLink="/near-stores"
                    style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                    type="button" class="btn btn-primary c-color4 border-color4">Size Yakın Diğer Mağazalar</button>
            </div>
        </div>
    </div>
</div>