<div style="height: 89%;overflow: auto;">
    <div style="border-bottom: 1px solid #00000026;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                        <img routerLink="/" style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                        <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 20px;" class="col-12">
                <div class="bg-color2" style="width: 100%;border-radius: 8px;padding-left: 15px;padding-right: 15px;">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div style="width: 100px;margin: auto;margin-top: 15px;">
                                <ng-lottie width="100px" [options]="migrosPinOptions" ></ng-lottie>
                            </div>
                            <!-- <ng-lottie width="100%" [options]="migrosPinOptions" ></ng-lottie> -->
    
                            <h2 class="c-color5" style="font-size: 17px;font-weight: 500;margin-top: 20px;line-height: 25px;margin-bottom: 10px;">{{nearStore?.MagazaAdi}}
                                <br>
                                Mağazasındasın!</h2>
    
                            <p class="c-color6" style="line-height: 22px;font-size: 14px;font-weight: 400;margin-top: 0px;">
                                İstediğin ürünü okutup sepete <br> ekleyebilirsin.</p>
                        </div>
    
                        <div  style="margin-bottom: 15px;" class="col-12 text-center">
                            <button (click)="createBasket()" style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">
                                <span *ngIf="(!productList || productList?.length == 0) || this.storeID != this.storeIdLocal">Alışverişe Başla</span>
                                <span *ngIf="productList?.length > 0 && this.storeID == this.storeIdLocal">Alışverişe Devam Et</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div style="margin-top: 15px;margin-bottom: 10px;" class="col-12">
                <span class="c-color4" style="font-size: 16px;font-weight: 500;">Sizin İçin Önerilen Kampanyalar</span>
                
            </div>
        </div>
    
        <div class="row">
            <!-- <div style="margin-top: 10px;" class="col-6">
                <div style="border: 1px solid #EEEEEE; border-radius: 4px;padding: 4px;">
                    <div style="width: 61px;border-radius: 2px;background: #65BAB0;color: white;font-size: 8px;display: inherit;text-align: center;">Son 3 Gün</div>
                    <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                        <img style="width: 57px;" src="../../assets/img/nutella.png">
                    </div>
                </div>

                <div style="align-items: center;" class="row">
                    <div style="margin-top: 9px;" class="col-6">
                        <span style="color: #00C851; font-weight: 500; font-size: 16px;">30,00 TL</span>
                    </div>
                    <div style="margin-top: 5px;" class="col-6">
                        <div style="background: #00C85126;border-radius: 18px 18px 18px 2px;font-size: 12px;color: #00C851;font-weight: 400; float: right; padding: 2px 4px 2px 4px;">-25%</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <span style="color: #888888; font-weight: 400; font-size: 12px;text-decoration: line-through;">50,00 TL</span>
                    </div>

                    <div class="col-12">
                        <span style="color: #484848; font-weight: 500; font-size: 12px;">Nutella Kakaolu Fındık Kreması</span>
                    </div>

                    <div class="col-12">
                        <span style="color: #888888; font-weight: 400; font-size: 12px;">500 gr</span>
                    </div>
                </div>

                <div style="margin-top: 5px;">
                    
                    
                </div>
            </div>

            <div style="margin-top: 10px;" class="col-6">
                <div style="border: 1px solid #EEEEEE; border-radius: 4px;padding: 4px;">
                    <div style="width: 61px;border-radius: 2px;background: #65BAB0;color: white;font-size: 8px;display: inherit;text-align: center;">Son 3 Gün</div>
                    <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                        <img style="width: 57px;" src="../../assets/img/nutella.png">
                    </div>
                </div>

                <div style="align-items: center;" class="row">
                    <div style="margin-top: 9px;" class="col-6">
                        <span style="color: #00C851; font-weight: 500; font-size: 16px;">30,00 TL</span>
                    </div>
                    <div style="margin-top: 5px;" class="col-6">
                        <div style="background: #00C85126;border-radius: 18px 18px 18px 2px;font-size: 12px;color: #00C851;font-weight: 400; float: right; padding: 2px 4px 2px 4px;">-25%</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <span style="color: #888888; font-weight: 400; font-size: 12px;text-decoration: line-through;">50,00 TL</span>
                    </div>

                    <div class="col-12">
                        <span style="color: #484848; font-weight: 500; font-size: 12px;">Nutella Kakaolu Fındık Kreması</span>
                    </div>

                    <div class="col-12">
                        <span style="color: #888888; font-weight: 400; font-size: 12px;">500 gr</span>
                    </div>
                </div>

                <div style="margin-top: 5px;">
                    
                    
                </div>
            </div> -->
            <div class="col-12">
                <div style="display: inline-flex;align-items: center;overflow: auto;">
                    <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf1.jpg">
                    <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf2.jpg">
                    <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf3.jpg">
                    <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf4.jpg">
                </div>
            </div>
        </div>
    </div>
    
    
</div>

<app-footer></app-footer>

<!-- Money Login Modal -->
<div class="modal fade" id="moneyLoginPopup" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div >
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <img style="width: 82px;margin-top: 8px;" src="../../assets/img/money-logo.svg">

                            <h4 class="c-color5" style="margin-top: 16px;line-height: 30px;font-size: 16px;font-weight: 500;margin-bottom: 16px;">
                                Money’e özel indirimlerden faydalanmak için üyeliğin ile giriş yapmak ister misin?</h4>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom:4px">
                        <div class="col-6">
                            <button data-bs-dismiss="modal" (click)="moneyLoginPopup()"
                                style="width: 100%;height: 50px;font-size: 16px;display:flex;align-items: center;justify-content: center; font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;"
                                type="button" class="btn btn-primary c-color6 border-color6 ">Hayır</button>
                        </div>
                        <div class="col-6">
                            <button routerLink="/login" data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px"
                                type="button" class="btn btn-primary bg-color1  c-color8" >Evet</button>
                        </div>
                       
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
