import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { CoreService } from '../services/core.service';

declare var $;

@Component({
  selector: 'app-store-location-check',
  templateUrl: './store-location-check.component.html',
  styleUrls: ['./store-location-check.component.css']
})
export class StoreLocationCheckComponent implements OnInit {

  migrosPinOptions: AnimationOptions = {
    path: '../../assets/img/migros-pin-new.json'
  };
  migrosPinOptions2: AnimationOptions = {
    path: '../../assets/img/migros-pin-error.json'
  };

  step = 1;
  public lat;
  public lng;
  nearStoreList: any = [];
  platform

  constructor(public coreService: CoreService, private router: Router) { }

  async ngOnInit() {
    console.log(navigator.geolocation)
    if (localStorage.getItem('locationPermission') == "true") {
      this.step = 2;
      await this.getLocation()
      await this.searchNearStoresByLatLong()
      //this.calculateDistance()
    }
    this.platform = this.coreService.getMobileOperatingSystem()
    console.log(this.platform)
  }

  async getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          console.log(this.lat);
          localStorage.setItem("lat",this.lat)
          localStorage.setItem("lng",this.lng)
          localStorage.setItem('locationPermission', "true")

          $('#checkLocationPermissionModal').modal('hide')
          this.searchNearStoresByLatLong()
        }
      },
        (error: any) => {
          console.log(error)
          localStorage.removeItem("lat")
          localStorage.removeItem("lng")
          localStorage.removeItem('locationPermission')
          $('#checkLocationPermissionModal').modal('hide')
          if(this.platform == "Android"){
            $("#androidLocationInfoModal").modal("show")
          }
          else if(this.platform == "iOS"){
            $("#iosLocationInfoModal").modal("show")
          }
          else {
            $("#androidLocationInfoModal").modal("show")
          }
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  calculateDistance(lat1,long1,lat2,long2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (long2-long1) * Math.PI/180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }

  async searchNearStoresByLatLong(){
    this.step = 2;
    const lat1 = localStorage.getItem("lat");
    const long1 = localStorage.getItem("lng");
    var data = "act=mkolay_getmagazalarall&myhash=21146545131232151321"

    this.coreService.coreServiceFunc(data).subscribe(data => {
      
      this.nearStoreList = data["ResultObjects"]
      console.log(this.calculateDistance(lat1,long1,data["ResultObjects"][0]["XKoordinat"],data["ResultObjects"][0]["YKoordinat"]))

      for (let index = 0; index < this.nearStoreList.length; index++) {
        this.nearStoreList[index]["DistanceKM"] = this.calculateDistance(lat1,long1,data["ResultObjects"][index]["XKoordinat"],data["ResultObjects"][index]["YKoordinat"]) / 1000
      }

      console.log(this.nearStoreList)
      const newArray = this.nearStoreList.filter(el => {
        return el.DistanceKM < 1
      })

      console.log(newArray)
      if(newArray.length == 0){
        this.step = 3;
      }
      else if(newArray.length > 0){
        this.router.navigateByUrl(`/store-maps`);
      }
    })
  }
  pageReload(){
    $("#iosLocationInfoModal").modal("hide")
    $("#androidLocationInfoModal").modal("hide")
    location.reload()
  }

}
