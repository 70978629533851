<div style="box-shadow: 0px 4px 8px #0000000d;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/check-location" style="width: 24px;margin-right: 8px;" src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500">Önerilen Mağazalar</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div style="margin-top: 20px;" class="col-12">
            <div class="bg-color3" style="width: 100%; border-radius: 8px;">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 12px;" class="col-12">
                            <h5 class="c-color1" style="font-size: 14px;font-weight: 400;text-align: center;">Size En
                                Yakın Migros</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div style="margin-bottom: 12px;" class="col-9">
                            <div style="display: inline-flex;align-items: center;">
                                <img style="width: 40px;margin-right: 4px;"
                                    src="../../assets/img/migros-pin-icon.png">
                                <div>
                                    <h5 class="c-color1"
                                        style="font-size: 16px;font-weight: 500;text-align: left;margin-bottom: 0;">
                                        {{nearStore?.MagazaAdi}}</h5>
                                    <span class="c-color4" style="font-size: 12px;font-weight: 300;">{{nearStore?.DistanceKM | number : '1.2-2'}} km</span>
                                </div>
                            </div>
                        </div>

                        <div style="text-align: right;margin-bottom: 12px;" class="col-3">
                            <img routerLink="/store/{{nearStore?.MagazaNo}}" style="width: 24px;" src="../../assets/img/next-icon-green.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div style="margin-top: 20px;" class="col-12">
            <span class="c-color4" style="font-size: 16px;font-weight: 500;">Size Yakın Diğer Mağazalar</span>
        </div>
    </div>

    <div class="row">
        <div *ngFor="let store of nearStoreList" style="margin-top: 20px;" class="col-12">
            <div style="width: 100%;background-color: #F7F7F7; border-radius: 8px;">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-bottom: 12px;margin-top: 12px;" class="col-9">
                            <div style="display: inline-flex;align-items: center;">
                                <img style="width: 40px;margin-right: 4px;"
                                    src="../../assets/img/migros-pin-icon.png">
                                <div>
                                    <h5 class="c-color5"
                                        style="font-size: 14px;font-weight: 500;text-align: left;margin-bottom: 0;">
                                        {{store?.MagazaAdi}}</h5>
                                    <span class="c-color4" style="font-size: 12px;font-weight: 300;">{{store?.DistanceKM | number : '1.2-2'}} km</span>
                                </div>
                            </div>
                        </div>

                        <div style="text-align: right;margin-bottom: 12px;margin-top: 12px;" class="col-3">
                            <div style="height: 100%;display: inline-flex;align-items: center;">
                                <img routerLink="/store/{{store?.MagazaNo}}" style="width: 24px;" src="../../assets/img/next-icon-black.png">
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>