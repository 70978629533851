<div *ngIf="!basketID || productList.length == 0">
    <div style="height: 89%;overflow: auto;">
        <div style="border-bottom: 1px solid #00000026;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                            <img style="width: 24px;margin-right: 8px;" (click)="goStore()" src="../../assets/img/back-icon-green.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">Sepetim</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div style="margin-top: 30px;" class="col-12 text-center">
                    <img style="width: 121px;" src="./../../assets/img/empty-basket-image.png">
                    <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Alışveriş Sepetin Boş
                    </h2>

                    <p class="c-color4" style="line-height: 24px;font-size: 14px;font-weight: 400;margin-top: 15px;">
                        Sepetinde ürün bulunmamaktadır. Hemen alışverişe başla!</p>
                </div>

                <div class="col-12 text-center">
                    <button (click)="checkStart()" 
                        style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 30px;"
                        type="button" class="btn btn-primary bg-color1 c-color8">Alışverişe Başla</button>
                </div>
            </div>

            <div class="row">
                <div style="margin-top: 40px;margin-bottom: 20px;" class="col-12">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Sizin İçin Önerilen
                        Kampanyanlar</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div style="display: inline-flex;align-items: center;overflow: auto;">
                        <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf1.jpg">
                        <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf2.jpg">
                        <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf3.jpg">
                        <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf4.jpg">
                      
                     
                    </div>
                </div>
            </div>
        </div>
    </div>


    <app-footer></app-footer>
</div>

<div *ngIf="productList?.length > 0">
    <div *ngIf="step == 1">
        <div style="border-bottom: 1px solid #00000026;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                    
                        <div class="d-flex justify-content-between">
                            <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                                <img (click)="goStore()" style="width: 24px;margin-right: 8px;"
                                    src="../../assets/img/back-icon-green.png">
                                <span class="c-color4" style="font-size: 16px;font-weight: 500;">Sepetim</span>
                            </div>
                            <div class="add-product" routerLink="/scan-add-product">
                                <div class="add-black-icon">
                                    <img width="12" src="../../assets/img/white-plus-icon.svg" alt="plus icon">
                                </div>
                                <span style="font-size: 12px;">Ürün Ekle</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-color2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="notify-box-add" [class.active]="notifyState">
                            <div
                                style="width: fit-content;border: 1px solid #00000014;margin-right: 5px;border-radius: 4px;">
                                <img style="width: 36px;margin-left: 10px;margin-right: 10px;"
                                    src="{{product?.ImageUrl}}">
                            </div>
                            <div style="margin-right: 5px;">
                                <img *ngIf="notifyStateAdd" style="width: 18px;" src="../../assets/img/check-icon.png">
                                <img *ngIf="notifyStateDelete" style="width: 18px;"
                                    src="../../assets/img/notify-delete-icon.png">
                            </div>
                            <div>
                                <h4 *ngIf="notifyStateAdd" class="c-color4"
                                    style="margin: 0;font-size: 13px;font-weight: 400;">Ürün sepetinize
                                    başarıyla eklendi!</h4>
                                <h4 *ngIf="notifyStateDelete" class="c-color4"
                                    style="margin: 0;font-size: 13px;font-weight: 400;">Ürün sepetinizden
                                    başarıyla çıkarıldı!</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 d-flex" style="min-height: 72px;  height: 100%;">
                     
                            <div style="display: flex;align-items: center;border-radius: 8px;">
                                <img style="width: 40px;margin-right: 4px;"
                                    src="../../assets/img/migros-white-pin-icon.png">
                                <div>
                                    <h5 class="c-color1"
                                        style="font-size: 16px;font-weight: 500;text-align: left;margin-bottom: 0;">
                                        {{storeName}}</h5>
                                    <span class="c-color4" style="font-size: 12px;font-weight: 300;">{{basketDate | date: 'd MMMM y':'':'tr'}}</span>
                                </div>
                            </div>
                    
                    </div>
                </div>
            </div>
        </div>

        <div style="overflow: auto;position: absolute;height: calc(100% - 259px); width: 100%;">
            <div class="container-fluid">
                <div *ngFor="let product of productList">
                    <div class="row" style="margin-top: 20px;">
                        <div  class="col-8">
                            <div style="display: inline-flex;align-items: center;">
                                <div
                                    style="min-width: 70px;max-width: 70px;border-radius: 12px;border: 1px solid #EEEEEE;padding: 6px;margin-right: 10px;">
                                    <img src="{{product?.ImageUrl}}" style="width: 100%;">
                                </div>
                                <div>
                                    <span class="c-color5"
                                        style="font-size: 13px;font-weight: 500;margin-bottom: 5px;">{{product?.productName}}</span>
                                    <br>
                                    <span class="c-color6"
                                        style="font-size: 12px;font-weight: 400;margin: 0;">{{product?.quantity}}
                                        {{product?.unit}}</span>
                                    <div *ngIf="product?.calcPrice != product?.discountedPrice">
                                        <span class="c-color6" style="font-size: 12px;font-weight: 400;margin: 0;text-decoration: line-through;margin-right: 8px;">
                                            {{product?.calcPrice| number : '1.2-2'}}TL
                                        </span>
                                        <span class="c-color1" style="font-size: 14px;font-weight: 500;margin: 0;">
                                            {{product?.discountedPrice | number : '1.2-2'}} TL
                                        </span> 
                                    </div>
                                    <div *ngIf="product?.calcPrice == product?.discountedPrice">
                                        <span class="c-color1" style="font-size: 14px;font-weight: 500;margin: 0;">
                                            {{product?.calcPrice | number : '1.2-2'}} TL
                                        </span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div style="margin-top: 20px;display: flex;align-items: center;" class="col-4 text-center">
                            <div
                                style="height:28px;border-radius: 26px;border: 1px solid #EEEEEE;margin: auto;display: inline-flex;align-items: center;">
                                <div (click)="deleteProductToBasket(product)">
                                    <img *ngIf="product?.quantity < 2" style="width: 20px;margin-left: 2px;"
                                        src="../../assets/img/delete-icon.png">
                                    <img *ngIf="product?.quantity >= 2" style="width: 20px;margin-left: 2px;"
                                        src="../../assets/img/mines-icon.png">
                                </div>
                                <div style="display: grid;margin-left: 10px;margin-right: 10px;">
                                    <span class="c-color5"
                                        style="font-size: 14px;font-weight: 500;margin-bottom: 2px;">{{product?.quantity}}</span>
                                </div>
                                <div *ngIf="product?.lockQuantity; else quantityEdit"></div>
                                <ng-template #quantityEdit>
                                    <div (click)="addProductToBasket(product)"
                                    class="border-color1"
                                        style="border: 1px solid;border-radius: 50%;width: 20px;height: 20px;display: inline-flex;align-items: center;margin-right: 2px;">
                                        <div style="width: 100%;">
                                            <img style="width: 12px;" src="../../assets/img/plus-icon.png">
                                        </div>
                                    </div>
                                </ng-template>
                           
                            </div>
                        </div>
                    </div>

                    <hr>
                </div>

            </div>
        </div>



        <div style="position: absolute;bottom: 0;width: 100%;">
            <div *ngIf="isCollapsed"
                style="box-shadow: 0px -2px 4px #0000000D;padding-top: 10px;padding-bottom: 10px;background: white;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-6 text-left">
                            <span class="c-color4" style="font-size: 14px;font-weight: 400;margin: 0;">Sepet
                                Tutarı:</span>
                        </div>
                        <div class="col-6 text-end">
                            <span class="c-color4" style="font-size: 14px;font-weight: 600;margin: 0;">{{totalPrice | number : '1.2-2'}}TL</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-left">
                            <span class="c-color1" style="font-size: 14px;font-weight: 400;margin: 0;">İndirim
                                Tutarı:</span>
                        </div>
                        <div class="col-6 text-end">
                            <span class="c-color1" style="font-size: 14px;font-weight: 600;margin: 0;">- {{totalDiscount |
                                number : '1.2-2'}}TL</span>
                        </div>
                    </div>
                </div>
            </div>

            <div style="box-shadow: 0px -2px 4px #0000000D;">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;margin-bottom: 20px;" class="col-4">
                            <div style="display: inline-flex;align-items: center;">
                                <div style="margin-right: 5px;">
                                    <img *ngIf="!isCollapsed" (click)="isCollapsed = true" style="width: 16px;"
                                        src="../../assets/img/collapse-bottom-icon.png">
                                    <img *ngIf="isCollapsed" (click)="isCollapsed = false" style="width: 16px;"
                                        src="../../assets/img/collapse-top-icon.png">
                                </div>
                                <div (click)="isCollapsed = !isCollapsed">
                                    <span class="c-color6"
                                        style="font-size: 10px;font-weight: 400;margin: 0;">Toplam</span>
                                    <br>
                                    <span class="c-color5"
                                        style="font-size: 16px;font-weight: 600;margin: 0;">{{totalPrice - totalDiscount
                                        | number : '1.2-2'}}TL</span>
                                </div>
                            </div>

                        </div>
                        <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8">
                            <button (click)="goPaymentStep()"
                                style="width: 100%;height: 44px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                                type="button" class="btn btn-primary bg-color1  c-color8" >Alışverişi Tamamla</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <!-- <div *ngIf="step == 2">
        <div style="box-shadow: 0px 4px 8px #0000000D;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                            <img (click)="step = 1" style="width: 24px;margin-right: 8px;"
                                src="../../assets/img/close-icon.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">Ödeme Seçenekleri</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-color2">
            <div class="container-fluid">
                <div class="row">
                    <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8 text-start">
                        <div style="display: inline-flex;align-items: center;">
                            <div
                                style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                                <img src="../../assets/img/tax-active-icon.png" style="width: 20px;">
                            </div>

                            <span class="c-color5" style="font-size: 16px;font-weight: 500;">İndirim Tutarı</span>
                        </div>
                    </div>
                    <div style="margin-top: 20px;margin-bottom: 20px;display: inline-grid;align-items: center;"
                        class="col-4 text-end">
                        <span class="c-color5" style="font-size: 16px;font-weight: 500;">₺{{totalDiscount | number :
                            '1.2-2'}}</span>
                    </div>
                </div>
                <hr style="margin: 0;border-bottom: 2px solid white;">
                <div class="row">
                    <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8 text-start">
                        <div style="display: inline-flex;align-items: center;">
                            <div
                                style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                                <img src="../../assets/img/credit-card-active-icon.png" style="width: 20px;">
                            </div>

                            <span class="c-color5" style="font-size: 16px;font-weight: 500;">Toplam Tutar</span>
                        </div>
                    </div>
                    <div style="margin-top: 20px;margin-bottom: 20px;display: inline-grid;align-items: center;"
                        class="col-4 text-end">
                        <span class="c-color5" style="font-size: 16px;font-weight: 500;">₺{{totalPrice + totalDiscount |
                            number : '1.2-2'}}</span>
                    </div>
                </div>

            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div style="margin-top: 15px;margin-bottom: 15px;" class="col-12">
                    <h2 class="c-color5" style="font-size: 16px;font-weight: 500;margin: 0;">Ödeme Yöntemini Belirle</h2>
                </div>
            </div>

            <div class="row">
                
                <div *ngIf="showJetKasa" class="col-12">
                    <div [class.active]="selectedPayment == 1" (click)="selectedPayment = 1;scroll(confirmPayment)" class="payment-box"
                        style="width: 100%;height: 68px;border-radius: 8px;padding: 12px;display: inline-flex;align-items: center;">
                        <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;align-items: center;width: 70%;">
                                <div *ngIf="selectedPayment == null || selectedPayment != 1"
                                    style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                                </div>
                                <img *ngIf="selectedPayment == 1" style="width: 20px;margin-right: 8px;"
                                    src="../../assets/img/payment-check-icon.png">
                                <span class="c-color4" style="font-size: 16px;font-weight: 500;">JetKasa İle Öde</span>
                            </div>
                            <div style="width: 30%;text-align: right;">
                                <img src="../../assets/img/jetkasa-icon.png" style="width: 41px;">
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 15px;" *ngIf="showMoneyPay" class="col-12">
                    <div [class.active]="selectedPayment == 2" (click)="selectedPayment = 2;scroll(confirmPayment)" class="payment-box"
                        style="width: 100%;height: 68px;border-radius: 8px;padding: 12px;display: inline-flex;align-items: center;">
                        <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;align-items: center;width: 70%;">
                                <div *ngIf="selectedPayment == null || selectedPayment != 2"
                                    style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                                </div>
                                <img *ngIf="selectedPayment == 2" style="width: 20px;margin-right: 8px;"
                                    src="../../assets/img/payment-check-icon.png">
                                <span class="c-color4" style="font-size: 16px;font-weight: 500;">MoneyPay İle Öde</span>
                            </div>
                            <div style="width: 30%;text-align: right;">
                                <img src="../../assets/img/moneypay-logo.png" style="width: 75px;">
                            </div>
                        </div>
                    </div>
                </div>
    
                <div style="margin-top: 15px;" *ngIf="showCreditCard" class="col-12">
                    <div [class.active]="selectedPayment == 3" (click)="selectedPayment = 3; scroll(confirmPayment)" class="payment-box"
                        style="width: 100%;height: 68px;border-radius: 8px;padding: 12px;display: inline-flex;align-items: center;">
                        <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;align-items: center;width: 70%;">
                                <div *ngIf="selectedPayment == null || selectedPayment != 3"
                                    style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                                </div>
                                <img *ngIf="selectedPayment == 3" style="width: 20px;margin-right: 8px;"
                                    src="../../assets/img/payment-check-icon.png">
                                <span class="c-color4" style="font-size: 16px;font-weight: 500;">Kredi Kartı İle Öde</span>
                            </div>
                            <div style="width: 30%;text-align: right;">
                                <img src="../../assets/img/basket-credit-card-icon.png" style="width: 42px;">
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px;padding-bottom: 140px;" class="col-12" >
                    <button #confirmPayment (click)="redirectSelectedPayment()" [disabled]="!selectedPayment"
                        style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                        type="button" class="btn btn-primary bg-color1  c-color8">Onayla</button>
                </div>
            </div>
        </div>

        <app-footer></app-footer>
    </div> -->

    <!-- <div *ngIf="step == 3">
        <div style="box-shadow: 0px 4px 8px #0000000D;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                            <img (click)="checkJetStep()" style="width: 24px;margin-right: 8px;"
                                src="../../assets/img/close-icon.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">Jet Kasa ile Öde</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div style="margin-top: 30px;" class="col-12 text-center">
                    <img style="width: 240px;" src="./../../assets/img/odeme-oncesi-image.png">
                    <h2 class="c-color5" style="font-size: 20px;font-weight: 600;margin-top: 20px;">Ödeme Öncesi
                        Bilgilendirme</h2>

                    <p class="c-color5" style="line-height: 24px;font-size: 16px;font-weight: 400;margin-top: 15px;">
                        Jet kasa üzerindeki <strong style="font-weight: 600;">MKolay butonuna tıklayıp, çıkan QR kodu
                            okutarak</strong> ödeme işlemine geçebilirsin.</p>
                </div>

                <div class="col-12 text-center">
                    <button routerLink="/payment/jetkasa/scan-qr"
                        style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                        type="button" class="btn btn-primary bg-color1  c-color8">Qr Kodu Okut</button>
                </div>
            </div>
        </div>

        <app-footer></app-footer>
    </div> -->

    <div *ngIf="step == 4">
        <div style="box-shadow: 0px 4px 8px #0000000D;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                            <img (click)="step = 3" style="width: 24px;margin-right: 8px;"
                                src="../../assets/img/close-icon.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">QR Kodu Okut</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-color7" style="width: 100%;height: 100%;position: fixed;">
            <div class="container-fluid">
                <div class="row">
                    <div style="margin-top: 120px;" class="col-12 text-center">
                        <div class="scandit-wrapper">
                            <div class="cam-border top-left"></div>
                            <div class="cam-border top-right"></div>
                            <div class="cam-border bottom-left"></div>
                            <div class="cam-border bottom-right"></div>
                            <div class="animated-scanner-line"></div>
                            <scandit-sdk-barcode-picker [enableCameraSwitcher]="false" [enableTorchToggle]="false"
                                #picker [playSoundOnScan]="true" [vibrateOnScan]="true"
                                [scanSettings]="activeSettingsQR" [singleImageModeSettings]="singleImageSettings"
                                [videoFit]="videoFit" (scan)="onScan($event)" [guiStyle]="scannerGuiStyle"
                                [style.height]="'80vw'" [style.width]="'100%'" [style.display]="'block'">
                            </scandit-sdk-barcode-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="step == 5">
        <div style="border-bottom: 1px solid #00000026;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                            <img style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div style="margin-top: 30px;" class="col-12 text-center">
                    <img style="width: 156px;" src="./../../assets/img/payment-success-image.png">
                    <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Alışverişiniz başarılı
                        bir <br> şekilde tamamlandı!</h2>
                </div>

                <div class="col-12 text-center">
                    <button 
                        style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                        type="button" class="btn btn-primary bg-color1  c-color8">Alışveriş Özetini Gör</button>
                </div>
            </div>
        </div>

        <app-footer></app-footer>
    </div>

    <div *ngIf="step == 6">
        <div style="border-bottom: 1px solid #00000026;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                            <img style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div style="margin-top: 30px;" class="col-12 text-center">
                    <img style="width: 130px;" src="./../../assets/img/payment-failed-image.png">
                    <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Üzgünüz, ödemeniz <br>
                        gerçekleşmedi.</h2>

                    <p class="c-color6" style="line-height: 30px;font-size: 16px;font-weight: 400;margin-top: 15px;">
                        MoneyPay hesabınızı kontrol ederek <br> tekrar deneyebilirsiniz.</p>
                </div>

                <div class="col-12 text-center">
                    <button (click)="step = 4"
                        style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                        type="button" class="btn btn-primary bg-color1  c-color8">Tekrar Dene</button>
                </div>
            </div>
        </div>

    

        <app-footer></app-footer>
    </div>



</div>