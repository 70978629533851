import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CoreService } from "../services/core.service";
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(public coreService: CoreService ){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if(request){
    //     localStorage.setItem('createBasketDate', Date.now().toString())
    // }
    // const customReq = request.clone({
    
    // });
    // return next.handle(customReq);

    const startTime: Date = new Date();
    console.log(request)
    console.log(this.coreService.decryptUsingTripleDES(request.body,false))
    const requestURL = this.coreService.decryptUsingTripleDES(request.body,false).split("=")[1].split("&")[0]
    console.log(requestURL)
    return next.handle(request).pipe(
      tap((response: HttpResponse<any>): void => {
        const responseTime = new Date().valueOf() - startTime.valueOf()
        this.coreService.saveEventwithParameters(`responsedur_${requestURL}`, responseTime)
      }),
    );
  }
}