<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/profile"  style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Alışveriş Geçmişim</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="height: 81%;overflow: auto;">
    <div class="container-fluid">
        <div class="row">
            <div *ngFor="let item of shoppingHistoryList" style="margin-top: 20px;" class="col-12">
                <div routerLink="/shopping-history/{{item.transactionHeader?.transactionId}}" style="width: 100%;background-color: #F7F7F7; border-radius: 8px;">
                    <div class="container-fluid">
                        <div class="row">
                            <div style="margin-bottom: 12px;margin-top: 12px;" class="col-9">
                                <div style="display: inline-flex;align-items: center;">
                                    <img style="width: 40px;margin-right: 12px;"
                                        src="../../assets/img/migros-pin-icon.png">
                                    <div>
                                        <h5 class="c-color5"
                                            style="font-size: 14px;font-weight: 500;text-align: left;margin-bottom: 0;">
                                            {{item.transactionHeader?.storeName}}</h5>
                                        <span class="c-color1" style="font-size: 12px;font-weight: 500;">{{item.transactionHeader?.totalAmount}} TL</span>
                                        <br>
                                        <span class="c-color6" style="font-size: 12px;font-weight: 300;">{{item.transactionHeader?.date}}</span>
                                    </div>
                                </div>
                            </div>
    
                            <div style="text-align: right;margin-bottom: 12px;margin-top: 12px;" class="col-3">
                                <div style="height: 100%;display: inline-flex;align-items: center;">
                                    <img style="width: 24px;" src="../../assets/img/next-icon-black.png">
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        
        </div>
    </div>
</div>


<app-footer></app-footer>