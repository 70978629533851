<div style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/profile" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Kayıtlı Kartlarım</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="height: 100%;" class="container-fluid">
    <div style="height: 100%;" class="row">
        <div class="col-12 text-center">
            <iframe width="100%" height="100%" [src]="testURLLink"></iframe>
        </div>
    </div>
</div>