import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
declare var $: any

@Component({
  selector: 'app-jetkasa-success',
  templateUrl: './jetkasa-success.component.html',
  styleUrls: ['./jetkasa-success.component.css']
})
export class JetkasaSuccessComponent implements OnInit {
  selectedStarValue: any
  storeID = localStorage.getItem("storeId")
  basketId = localStorage.getItem("basketId")
  userInfo
  stars: any = [
    { "id": 1, "value": 1, "url": "assets/img/star-empty.svg" },
    { "id": 2, "value": 2, "url": "assets/img/star-empty.svg" },
    { "id": 3, "value": 3, "url": "assets/img/star-empty.svg" },
    { "id": 4, "value": 4, "url": "assets/img/star-empty.svg" },
    { "id": 5, "value": 5, "url": "assets/img/star-empty.svg" }
  ]
  constructor(public coreService: CoreService) {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
  }

  ngOnInit(): void {
    if (this.basketId) {
      setTimeout(() => {
        this.modalOpen('rating')
      }, 1000);
    }
  }


  modalOpen(modal: any) {
    $(`#${modal}`).modal('show')
  }

  modalClose(modal: any) {
    $(`#${modal}`).modal('hide')
  }

  setRating(value: number) {
    this.selectedStarValue = value + 1
    console.log(this.selectedStarValue)
    for (let index = 0; index < this.stars.length; index++) {
      this.stars[index].url = "assets/img/star-empty.svg"
    }

    for (let index = 0; index < value + 1; index++) {
      this.stars[index].url = "assets/img/star.svg"
    }
  }

  completePayment() {

    localStorage.removeItem('basketId')
    localStorage.removeItem('basketDate')
    localStorage.removeItem('createBasketDate')
    localStorage.removeItem('step')
    localStorage.removeItem('transactionId')
    localStorage.removeItem('jetQrCode')
    localStorage.removeItem('paymentFlow')
    localStorage.removeItem('isShowedLoginModal')
    this.coreService.testRXJS([])

  }


  sendScore() {
    var data = `act=mkolay_score&basketid=${this.basketId}&score=${this.selectedStarValue}&gsmid=${this.userInfo.gsmId}&cardid=${this.userInfo.cardId}`
    this.coreService.coreServiceFunc(data).subscribe(async data => {
      this.modalClose('rating')
      this.completePayment()
    })
  }


}
