<div >
    <div style="box-shadow: 0px 4px 8px #0000000D;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div routerLink="/basket" style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                        <img style="width: 24px;margin-right: 8px;"
                            src="../../assets/img/close-icon.png">
                        <span class="c-color4" style="font-size: 16px;font-weight: 500;">Ödeme Seçenekleri</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-color2">
        <div class="container-fluid">
            <div class="row">
                <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8 text-start">
                    <div style="display: inline-flex;align-items: center;">
                        <div
                            style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                            <img src="../../assets/img/tax-active-icon.png" style="width: 20px;">
                        </div>

                        <span class="c-color5" style="font-size: 16px;font-weight: 500;">İndirim Tutarı</span>
                    </div>
                </div>
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-grid;align-items: center;"
                    class="col-4 text-end">
                    <span class="c-color5" style="font-size: 16px;font-weight: 500;">₺{{totalDiscount | number :
                        '1.2-2'}}</span>
                </div>
            </div>
            <hr style="margin: 0;border-bottom: 2px solid white;">
            <div class="row">
                <div style="margin-top: 20px;margin-bottom: 20px;" class="col-8 text-start">
                    <div style="display: inline-flex;align-items: center;">
                        <div
                            style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                            <img src="../../assets/img/credit-card-active-icon.png" style="width: 20px;">
                        </div>

                        <span class="c-color5" style="font-size: 16px;font-weight: 500;">Toplam Tutar</span>
                    </div>
                </div>
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-grid;align-items: center;"
                    class="col-4 text-end">
                    <span class="c-color5" style="font-size: 16px;font-weight: 500;">₺{{totalPrice + totalDiscount |
                        number : '1.2-2'}}</span>
                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 15px;margin-bottom: 15px;" class="col-12">
                <h2 class="c-color5" style="font-size: 16px;font-weight: 500;margin: 0;">Ödeme Yöntemini Belirle</h2>
            </div>
        </div>

        <div class="row">
            
            <div *ngIf="showJetKasa" class="col-12">
                <div [class.active]="selectedPayment == 1" (click)="selectedPayment = 1;scroll(confirmPayment)" class="payment-box"
                    style="width: 100%;height: 68px;border-radius: 8px;padding: 12px;display: inline-flex;align-items: center;">
                    <div style="display: inline-flex;align-items: center;width: 100%;">
                        <div style="display: inline-flex;align-items: center;width: 70%;">
                            <div *ngIf="selectedPayment == null || selectedPayment != 1"
                                style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                            </div>
                            <img *ngIf="selectedPayment == 1" style="width: 20px;margin-right: 8px;"
                                src="../../assets/img/payment-check-icon.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">JetKasa İle Öde</span>
                        </div>
                        <div style="width: 30%;text-align: right;">
                            <img src="../../assets/img/jetkasa-icon.png" style="width: 41px;">
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 15px;" *ngIf="showMoneyPay" class="col-12">
                <div [class.active]="selectedPayment == 2" (click)="selectedPayment = 2;scroll(confirmPayment)" class="payment-box"
                    style="width: 100%;height: 68px;border-radius: 8px;padding: 12px;display: inline-flex;align-items: center;">
                    <div style="display: inline-flex;align-items: center;width: 100%;">
                        <div style="display: inline-flex;align-items: center;width: 70%;">
                            <div *ngIf="selectedPayment == null || selectedPayment != 2"
                                style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                            </div>
                            <img *ngIf="selectedPayment == 2" style="width: 20px;margin-right: 8px;"
                                src="../../assets/img/payment-check-icon.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">MoneyPay İle Öde</span>
                        </div>
                        <div style="width: 30%;text-align: right;">
                            <img src="../../assets/img/moneypay-logo.png" style="width: 75px;">
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 15px;" *ngIf="showCreditCard" class="col-12">
                <div [class.active]="selectedPayment == 3" (click)="selectedPayment = 3; scroll(confirmPayment)" class="payment-box"
                    style="width: 100%;height: 68px;border-radius: 8px;padding: 12px;display: inline-flex;align-items: center;">
                    <div style="display: inline-flex;align-items: center;width: 100%;">
                        <div style="display: inline-flex;align-items: center;width: 70%;">
                            <div *ngIf="selectedPayment == null || selectedPayment != 3"
                                style="width: 20px;height: 20px;border-radius: 50%;border: 1px solid #EEEEEE;margin-right: 8px;">
                            </div>
                            <img *ngIf="selectedPayment == 3" style="width: 20px;margin-right: 8px;"
                                src="../../assets/img/payment-check-icon.png">
                            <span class="c-color4" style="font-size: 16px;font-weight: 500;">Kredi Kartı İle Öde</span>
                        </div>
                        <div style="width: 30%;text-align: right;">
                            <img src="../../assets/img/basket-credit-card-icon.png" style="width: 42px;">
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 20px;padding-bottom: 140px;" class="col-12" >
                <button #confirmPayment (click)="redirectSelectedPayment()" [disabled]="!selectedPayment"
                    style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                    type="button" class="btn btn-primary bg-color1  c-color8">Onayla</button>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>

<div class="modal fade" id="moneyLoginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div >
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <img style="width: 82px;margin-top: 8px;" src="../../assets/img/mobil-payment-icon.png">

                            <h4 class="c-color5" style="margin-top: 16px;line-height: 30px;font-size: 16px;font-weight: 500;margin-bottom: 16px;">
                                Mobil ödeme yapmak istiyorsan money üyeliğin ile giriş yap</h4>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom:4px">
                        <div class="col-6">
                            <button data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;"
                                type="button" class="btn btn-primary c-color6 border-color6 ">Vazgeç</button>
                        </div>
                        <div class="col-6">
                            <button routerLink="/login" data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px"
                                type="button" class="btn btn-primary bg-color1  c-color8" >Devam Et</button>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>