<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img  (click)="back()" style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Hesap</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="box-shadow: 0px 8px 16px #0000000d;border-bottom-left-radius:16px; border-bottom-right-radius: 16px">
    <div *ngIf="isLogged" class="container-fluid">
        <div class="row">
            <div style="margin-top: 32px;margin-bottom: 10px;" class="col-12 text-center">
                <div style="width: 56px;height: 56px;border: 1px solid #EEEEEE;display: inline-flex;align-items: center;justify-content: center;border-radius: 50%;">
                    <span class="c-color5" style="font-size: 20px;font-weight: 500;margin: 0;">{{nameInitial}}</span>
                </div>
            </div>
            <div style="margin-bottom: 32px;" class="col-12 text-center">
                <span class="c-color5" style="font-size: 16px;font-weight: 500;margin-top: 0;margin-bottom: 0px;">{{userInfo.fullName}}</span>
                <h2 class="c-color6" style="font-size: 12px;font-weight: 400;margin: 0;">0{{userInfo.phoneNumber}}</h2>
            </div>
        </div>
    </div>
    <div *ngIf="!isLogged" class="container-fluid">
        <div class="row">
            <div style="margin-top: 20px;margin-bottom: 10px;" class="col-12 text-center">
                <p class="c-color4" style="font-size: 14px;font-weight: 400;margin: 0;">Farklı özelliklere erişmek için Money üyesi olabilirsin.</p>

                <button routerLink="/signup" style="width: 230px;height: 42px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 20px;"
                type="button" class="btn btn-primary bg-color1 c-color8">Üye Ol</button>
                
            </div>
            <div class="col-12 text-center">
                <h2 class="c-color4" style="font-size: 12px;font-weight: 400;margin-top: 10px;">Zaten üye misin? <span routerLink="/login" class="c-color1">Giriş Yap</span></h2>
            </div>
            
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row" style="margin-top: 51px;">
        <!-- <button type="button" (click)="resetStore()" style="margin-bottom: 10px;padding: 0.35rem 0.75rem; display: flex;" class="col-12 btn profile-btn">
            <div style="width: 100%;display: inline-flex;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;">
                            <img src="../../assets/img/profile-icon.png" style="width: 24px;margin-right: 10px;">
                            <span class="c-color5" style="font-size: 16px;font-weight: 400;">Mağaza Değiştir</span>
                        </div>
                        
                    </div>
                    <div style="width: 15%;text-align: right;">
                        <img src="../../assets/img/next-icon-transparent.png" style="width: 24px;">
                    </div>
                </div>
            </div>
        </button> -->

        <!-- <div style="margin-bottom: 20px;" class="col-12">
            <div style="width: 100%;display: inline-flex;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;" >
                            <img src="../../assets/img/profile-icon-2.png" style="width: 24px;margin-right: 10px;">
                            <span class="c-color5" style="font-size: 16px;font-weight: 400;">Canlı Destek</span>
                        </div>
                        
                    </div>
                    <div style="width: 15%;text-align: right;">
                        <img src="../../assets/img/next-icon-transparent.png" style="width: 24px;">
                    </div>
                </div>
            </div>
        </div> -->

        <button type="button" style="margin-bottom: 10px;padding: 0.35rem 0.75rem; display: flex;" class="col-12 btn profile-btn">
            <div style="width: 100%;display: inline-flex;align-items: center;" >
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;" (click)="recordedCards()" >
                            <img [src]="this.isLogged ? '../../assets/img/profile-icon-3.png' : '../../assets/img/profile-icon-3-passive.png'" src="../../assets/img/profile-icon-3.png" style="width: 24px;margin-right: 10px;">
                            <span [ngClass]="this.isLogged ? 'c-color5' : 'c-color10'"  style="font-size: 16px;font-weight: 400;">Kayıtlı Kartlarım</span>
                        </div>
                        
                    </div>
                    <div style="width: 15%;text-align: right;">
                        <img [src]="this.isLogged ? '../../assets/img/next-icon-transparent.png' : '../../assets/img/next-icon-passive.png'" style="width: 24px;">
                    </div>
                </div>
            </div>
        </button>

        <button [routerLink]="this.isLogged ? '/shopping-history' : null"  type="button" style="margin-bottom: 10px;padding: 0.35rem 0.75rem; display: flex;" class="col-12 btn profile-btn" >
            <div style="width: 100%;display: inline-flex;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;">
                            <img [src]="this.isLogged ? '../../assets/img/profile-icon-4.png' : '../../assets/img/profile-icon-4-passive.png'" style="width: 24px;margin-right: 10px;">
                            <span [ngClass]="this.isLogged ? 'c-color5' : 'c-color10'"  style="font-size: 16px;font-weight: 400;">Alışveriş Geçmişim</span>
                        </div>
                    </div>
                    <div  style="width: 15%;text-align: right;">
                        <img [src]="this.isLogged ? '../../assets/img/next-icon-transparent.png' : '../../assets/img/next-icon-passive.png'" style="width: 24px;">
                    </div>
                </div>
            </div>
        </button>

        <button  type="button" style="margin-bottom: 10px;padding: 0.35rem 0.75rem; display: flex;" class="col-12 btn profile-btn">
            <div style="width: 100%;display: inline-flex;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;">
                            <img src="../../assets/img/profile-icon-5.png" style="width: 24px;margin-right: 10px;">
                            <span class="c-color5" style="font-size: 16px;font-weight: 400;">Kişisel Verilerin Korunması</span>
                        </div>
                        
                    </div>
                    <div style="width: 15%;text-align: right;">
                        <img src="../../assets/img/next-icon-transparent.png" style="width: 24px;">
                    </div>
                </div>
            </div>
        </button>
        <button  type="button" style="margin-bottom: 10px;padding: 0.35rem 0.75rem; display: flex;" class="col-12 btn profile-btn" *ngIf="this.isLogged">
            <div style="width: 100%;display: inline-flex;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;" (click)="logout()">
                            <img src="../../assets/img/profile-icon-6.png" style="width: 24px;margin-right: 10px;">
                            <span class="c-color5" style="font-size: 16px;font-weight: 400;">Çıkış Yap</span>
                        </div>
                        
                    </div>
                    <div style="width: 15%;text-align: right;">
                        <img src="../../assets/img/next-icon-transparent.png" style="width: 24px;">
                    </div>
                </div>
            </div>
        </button>

        <!-- <div style="margin-top: 20px;" class="col-12">
            <div style="width: 100%;display: inline-flex;align-items: center;">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 85%;">
                        <div style="display: inline-flex;align-items: center;">
                            <img src="../../assets/img/profile-icon-6.png" style="width: 24px;margin-right: 10px;">
                            <span class="c-color5" style="font-size: 16px;font-weight: 400;">Çıkış Yap</span>
                        </div>
                        
                    </div>
                    <div style="width: 15%;text-align: right;">
                        <img src="../../assets/img/next-icon-transparent.png" style="width: 24px;">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>


<app-footer></app-footer>