import { Component, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Barcode, BarcodePicker, ScanResult, ScanSettings, SingleImageModeSettings } from 'scandit-sdk';
import { CoreService } from 'src/app/services/core.service';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jetkasa-scan-qr',
  templateUrl: './jetkasa-scan-qr.component.html',
  styleUrls: ['./jetkasa-scan-qr.component.css']
})
export class JetkasaScanQrComponent implements OnInit {

  step = 1
  intervalTimer;

  public videoFitCover: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.COVER;
  public videoFit: BarcodePicker.ObjectFit = this.videoFitCover;
  public scannerGuiStyle: BarcodePicker.GuiStyle = BarcodePicker.GuiStyle.VIEWFINDER;
  public scannedCodes: Barcode[] = [];
  public scannedBarcodes: Barcode[] = [];
  public settingsQR: ScanSettings;
  public settings128: ScanSettings;
  public activeSettingsQR: ScanSettings;
  public activeSettingsBarcode: ScanSettings;

  public singleImageSettings: SingleImageModeSettings;

  cardNumber = localStorage.getItem('virtualOrCardId')
  basketID;
  jetQrCode;
  productList: any = [];
  productDetails;
  paymentOptionList
  subscription !: Subscription;

  isStarted = false;
  isDisable = false;

  constructor(public coreService: CoreService, private toastrService: ToastrService,private router:Router) {
    this.settingsQR = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.QR],
      codeDuplicateFilter: 2000
    });

    this.settings128 = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.CODE128, Barcode.Symbology.EAN13, Barcode.Symbology.EAN8, Barcode.Symbology.CODE39, Barcode.Symbology.CODE93, Barcode.Symbology.PDF417, Barcode.Symbology.AZTEC],
      codeDuplicateFilter: 2000
    });

    this.activeSettingsQR = this.settingsQR;
    this.activeSettingsBarcode = this.settings128

    this.singleImageSettings = {
      desktop: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
      mobile: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
    };
  }

  async ngOnInit() {
    this.basketID = localStorage.getItem('basketId')
    this.paymentOptionList = JSON.parse(localStorage.getItem("paymentOptionList"))
    //this.jetQrCode = localStorage.getItem('jetQrCode')

    //this.scanJetKasa(this.jetQrCode)
    // if (this.basketID) {
    //   await this.getBasket()
    // }
  }

  async onScan(result: ScanResult) {
    if (this.scannedCodes.length == 0 && !this.isDisable) {
      this.scannedCodes = this.scannedCodes.concat(result.barcodes);
      console.log(this.scannedCodes[0].data)
      this.jetQrCode = this.scannedCodes[0].data
      this.isDisable = true
      await this.scanJetKasa(this.jetQrCode)
    }
  }

  async payWithQR() {
    // var requestCount = 1;
    // if(!this.isStarted && requestCount == 1){
    //   var timer = setInterval(() => {
    //     this.getBasket(requestCount)
    //     console.log(requestCount)
    //     requestCount++
    //     if(requestCount == 5){
    //       clearInterval(timer)
    //     }
    //   }, 5000);
    //   this.isStarted = true
    // }
    this.isDisable = true

    await this.scanJetKasa(this.jetQrCode)

  }

  async scanJetKasa(qrcode) {
    var data = `act=mkolay_scanjetkasa&cardnumber=${this.cardNumber}&basketId=${this.basketID}&qrcode=${qrcode}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(async data => {

      if (data) {
        if (data["result"] == 0) {
          // setTimeout(() => {
          //   this.getBasket(1)
          // }, 5000);
          await this.getBasket(1)
        }
        else if (data["result"] == 1) {
          this.toastrService.error(data["systemMessage"], "Hata")
          this.isDisable = false
        }

        console.log(data)
      }
      else {
        this.isDisable = false
      }

    }, (err => {
      console.log(err)
      this.isDisable = false
    }))
  }

  async getBasket(callNumber) {
    if (callNumber == 1) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&callno=${callNumber}&myhash=1231231233475638457&nocache=1`

      this.coreService.coreServiceFunc(data).subscribe(async data => {

        this.productList = data["resultData"]["productList"]
        this.productDetails = data["resultData"]
        console.log(this.productDetails)
        console.log("FIRST_REQUEST")
        if (this.productDetails.checkRequired == 1) {
          location.href = "/basket"
        }
        else if (this.productDetails.checkRequired == 2) {
          location.href = "/payment/jetkasa/success"
        }
        else if (this.productDetails.checkRequired == "" || this.productDetails.checkRequired == 0) {
          setTimeout(() => {
            this.getBasket(2)
          }, 5000);
        }

      }, (err => {
        console.log(err)
        this.isDisable = false
      }))
    }
    else if (callNumber == 2) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&callno=${callNumber}&myhash=1231231233475638457&nocache=1`

      this.coreService.coreServiceFunc(data).subscribe(async data => {

        this.productList = data["resultData"]["productList"]
        this.productDetails = data["resultData"]
        console.log(this.productDetails)
        console.log("SECOND_REQUEST")
        if (this.productDetails.checkRequired == 1) {
          location.href = "/basket"
        }
        else if (this.productDetails.checkRequired == 2) {
          location.href = "/payment/jetkasa/success"
        }
        else if (this.productDetails.checkRequired == "" || this.productDetails.checkRequired == 0) {
          setTimeout(() => {
            this.getBasket(3)
          }, 5000);
        }
      }, (err => {
        console.log(err)
        this.isDisable = false
      }))
    }
    else if (callNumber == 3) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&callno=${callNumber}&myhash=1231231233475638457&nocache=1`

      this.coreService.coreServiceFunc(data).subscribe(async data => {

        this.productList = data["resultData"]["productList"]
        this.productDetails = data["resultData"]
        console.log(this.productDetails)
        console.log("THIRD_REQUEST")
        if (this.productDetails.checkRequired == 1) {
          location.href = "/basket"
        }
        else if (this.productDetails.checkRequired == 2) {
          location.href = "/payment/jetkasa/success"
        }
        else if (this.productDetails.checkRequired == "" || this.productDetails.checkRequired == 0) {
          setTimeout(() => {
            this.getBasket(4)
          }, 5000);
        }
      }, (err => {
        console.log(err)
        this.isDisable = false
      }))
    }
    else if (callNumber == 4) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&callno=${callNumber}&myhash=1231231233475638457&nocache=1`

      this.coreService.coreServiceFunc(data).subscribe(async data => {

        this.productList = data["resultData"]["productList"]
        this.productDetails = data["resultData"]
        console.log(this.productDetails)
        console.log("THIRD_REQUEST")
        if (this.productDetails.checkRequired == 1) {
          location.href = "/basket"
        }
        else if (this.productDetails.checkRequired == 2) {
          location.href = "/payment/jetkasa/success"
        }
        else if (this.productDetails.checkRequired == "" || this.productDetails.checkRequired == 0) {
          location.href = "/payment/jetkasa/success"
        }
      }, (err => {
        console.log(err)
        this.isDisable = false
      }))
    }

  }

  async checkBasket() {
    this.subscription = timer(0, 5000).pipe(
      switchMap(async () => console.log("test"))
    ).subscribe(result =>
      console.log(result)
    );



  }

  one() {
    setTimeout(() => {
      this.one();
    }, 5000)
  }

  onNavigatePaymentOptions(){

    if(this.paymentOptionList.moneyPay || this.paymentOptionList.creditCard){
      location.href = "/payment/payment-options"
    }
    else if(!this.paymentOptionList.moneyPay && !this.paymentOptionList.creditCard && this.paymentOptionList.jetKasa)
    location.href = "basket"
    }

}
