import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-clear-cache',
  templateUrl: './clear-cache.component.html',
  styleUrls: ['./clear-cache.component.css']
})
export class ClearCacheComponent implements OnInit {

  lat = localStorage.getItem("lat")?.toString() ?? "";
  lng = localStorage.getItem("lng")?.toString() ?? "";
  virtualOrCardId = localStorage.getItem("virtualOrCardId")?.toString() ?? "null";
  basketId = localStorage.getItem("basketId")?.toString() ?? "null";
  jetQrCode = localStorage.getItem("jetQrCode")?.toString() ?? "null";
  basketDate = localStorage.getItem("basketDate")?.toString() ?? "null";
  createBasketDate = localStorage.getItem("createBasketDate")?.toString() ?? "null";
  storeId = localStorage.getItem("storeId")?.toString() ?? "null"
  storeName = localStorage.getItem("storeName")?.toString() ?? "null"
  userInfo = localStorage.getItem("userInfo")?.toString() ?? "null"

  testURLLink;
  constructor(private sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void {
    this.testURLLink = this.sanitizer.bypassSecurityTrustResourceUrl("https://mkolay.moneypay.com.tr/#/list?userToken=c8f422ee-69df-9aa6-22e1-7e4ffadec363");
    //this.testURLLink = this.sanitizer.bypassSecurityTrustResourceUrl("http://localhost:4200/");
  }

  clearCache(){
    localStorage.clear()
    location.href = ""
  }

  testAndroid(){
    //location.href = "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";
    location.href = "intent://moneypay.io/#Intent;scheme=https;package=com.colendi.money_pay;end";
  }

  testURL(event){
    console.log(event)
  }

  transform() {
    
    
  }

}
