<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/search" style="width: 24px;margin-right: 8px;" src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500">{{nearStore?.MagazaAdi}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="display: inline-flex;overflow: auto;">
    <img src="../../assets/img/slider-image.png" style="width: 100%;margin-right: 15px;height: 28vh;">
    <img src="../../assets/img/slider-image.png" style="width: 100%;margin-right: 15px;height: 28vh;">
</div>

<div class="container-fluid">
    <div class="row">
        <div style="margin-top: 20px;" class="col-12">
            <div style="display: inline-flex; align-items: center;">
                <img style="width: 23px;margin-right: 5px;" src="../../assets/img/migros-pin-icon.png">
                <span class="c-color5" style="font-size: 16px;font-weight: 400;">{{nearStore?.MagazaAdi}}</span>
            </div>
        </div>
    
        <div class="col-12">
            <span class="c-color6" style="font-size: 12px;font-weight: 400">{{nearStore?.Adres}} <br> {{nearStore?.Ilce}}/{{nearStore?.Il}}</span>
        </div>
    
        <div class="col-12">
            <div class="c-color1" style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;background-color: #eff8f7 !important; border-radius: 8px;margin-top: 20px;display: inline-flex;align-items: center;">
               <img style="width: 20px;margin-right: 5px;margin-left: 12px;" src="../../assets/img/clock-icon.png">
               <span class="c-color1"  style="font-size: 14px;font-weight: 500;">Çalışma Saatleri: <span style="font-size: 14px;font-weight: 400;">{{nearStore?.Acilis}} - {{nearStore?.Kapanis}}</span></span>
            </div>
        </div>
    
        <div style="margin-top: 15px;" class="col-12">
            <div class="c-color1"  style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;background-color: #eff8f7 !important; border-radius: 8px;display: inline-flex;align-items: center;">
               <img style="width: 20px;margin-right: 5px;margin-left: 12px;" src="../../assets/img/phone-icon.png">
               <span class="c-color1"  style="font-size: 14px;font-weight: 500;">Telefon: <span style="font-size: 14px;font-weight: 400;">{{nearStore?.Tel}}</span></span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-center">
            <button routerLink="/store/{{nearStore?.MagazaNo}}"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;border-radius: 8px;margin-top: 60px;"
                type="button" class="btn btn-primary bg-color1 c-color8">Alışverişe Başla</button>
        </div>

        <div class="col-12 text-center">
            <button routerLink="/store-maps"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                type="button" class="btn btn-primary c-color4 border-color4">Haritaya Dön</button>
        </div>
    </div>
    
</div>