<div style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Size Özel Kampanyalar</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div style="margin-top: 10px;" class="col-6">
            <div style="border: 1px solid #EEEEEE; border-radius: 4px;padding: 4px;">
                <div style="width: 61px;border-radius: 2px;background: #65BAB0;color: white;font-size: 8px;display: inherit;text-align: center;">Son 3 Gün</div>
                <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                    <img style="width: 57px;" src="../../assets/img/nutella.png">
                </div>
            </div>

            <div style="align-items: center;" class="row">
                <div style="margin-top: 9px;" class="col-6">
                    <span style="color: #00C851; font-weight: 500; font-size: 16px;">30,00 TL</span>
                </div>
                <div style="margin-top: 5px;" class="col-6">
                    <div style="background: #00C85126;border-radius: 18px 18px 18px 2px;font-size: 12px;color: #00C851;font-weight: 400; float: right; padding: 2px 4px 2px 4px;">-25%</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;text-decoration: line-through;">50,00 TL</span>
                </div>

                <div class="col-12">
                    <span style="color: #484848; font-weight: 500; font-size: 12px;">Nutella Kakaolu Fındık Kreması</span>
                </div>

                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;">500 gr</span>
                </div>
            </div>

            <div class="row">
                <div style="margin-top: 5px;" class="col-12">
                    <div routerLink="/special-offers/add-product/8698996006988" style="background-color: #65BAB01A;border-radius: 4px;padding: 6px;">
                        <img style="width: 20px;margin-right: 6px;" src="../../assets/img/barcode-icon.png">
                        <span style="font-weight: 400;font-size: 11px;color: #65BAB0;margin: 0;">Ürün Barkodunu Okut</span>
                    </div>
                </div>
            </div>

        </div>

        <div style="margin-top: 10px;" class="col-6">
            <div style="border: 1px solid #EEEEEE; border-radius: 4px;padding: 4px;">
                <div style="width: 61px;border-radius: 2px;background: #65BAB0;color: white;font-size: 8px;display: inherit;text-align: center;">Son 3 Gün</div>
                <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                    <img style="width: 57px;" src="../../assets/img/nutella.png">
                </div>
            </div>

            <div style="align-items: center;" class="row">
                <div style="margin-top: 9px;" class="col-6">
                    <span style="color: #00C851; font-weight: 500; font-size: 16px;">30,00 TL</span>
                </div>
                <div style="margin-top: 5px;" class="col-6">
                    <div style="background: #00C85126;border-radius: 18px 18px 18px 2px;font-size: 12px;color: #00C851;font-weight: 400; float: right; padding: 2px 4px 2px 4px;">-25%</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;text-decoration: line-through;">50,00 TL</span>
                </div>

                <div class="col-12">
                    <span style="color: #484848; font-weight: 500; font-size: 12px;">Nutella Kakaolu Fındık Kreması</span>
                </div>

                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;">500 gr</span>
                </div>
            </div>

            <div class="row">
                <div style="margin-top: 5px;" class="col-12">
                    <div routerLink="/special-offers/add-product/8698996006988" style="background-color: #65BAB01A;border-radius: 4px;padding: 6px;">
                        <img style="width: 20px;margin-right: 6px;" src="../../assets/img/barcode-icon.png">
                        <span style="font-weight: 400;font-size: 11px;color: #65BAB0;margin: 0;">Ürün Barkodunu Okut</span>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="col-12">
            <div style="display: inline-flex;align-items: center;overflow: auto;">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf1.jpg">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf2.jpg">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf3.jpg">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf4.jpg">
            </div>
        </div> -->
    </div>

    <div class="row">
        <div style="margin-top: 10px;" class="col-6">
            <div style="border: 1px solid #EEEEEE; border-radius: 4px;padding: 4px;">
                <div style="width: 61px;border-radius: 2px;background: #65BAB0;color: white;font-size: 8px;display: inherit;text-align: center;">Son 3 Gün</div>
                <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                    <img style="width: 57px;" src="../../assets/img/nutella.png">
                </div>
            </div>

            <div style="align-items: center;" class="row">
                <div style="margin-top: 9px;" class="col-6">
                    <span style="color: #00C851; font-weight: 500; font-size: 16px;">30,00 TL</span>
                </div>
                <div style="margin-top: 5px;" class="col-6">
                    <div style="background: #00C85126;border-radius: 18px 18px 18px 2px;font-size: 12px;color: #00C851;font-weight: 400; float: right; padding: 2px 4px 2px 4px;">-25%</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;text-decoration: line-through;">50,00 TL</span>
                </div>

                <div class="col-12">
                    <span style="color: #484848; font-weight: 500; font-size: 12px;">Nutella Kakaolu Fındık Kreması</span>
                </div>

                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;">500 gr</span>
                </div>
            </div>

            <div class="row">
                <div style="margin-top: 5px;" class="col-12">
                    <div style="background-color: #65BAB01A;border-radius: 4px;padding: 6px;">
                        <img style="width: 20px;margin-right: 6px;" src="../../assets/img/barcode-icon.png">
                        <span style="font-weight: 400;font-size: 11px;color: #65BAB0;margin: 0;">Ürün Barkodunu Okut</span>
                    </div>
                </div>
            </div>

        </div>

        <div style="margin-top: 10px;" class="col-6">
            <div style="border: 1px solid #EEEEEE; border-radius: 4px;padding: 4px;">
                <div style="width: 61px;border-radius: 2px;background: #65BAB0;color: white;font-size: 8px;display: inherit;text-align: center;">Son 3 Gün</div>
                <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                    <img style="width: 57px;" src="../../assets/img/nutella.png">
                </div>
            </div>

            <div style="align-items: center;" class="row">
                <div style="margin-top: 9px;" class="col-6">
                    <span style="color: #00C851; font-weight: 500; font-size: 16px;">30,00 TL</span>
                </div>
                <div style="margin-top: 5px;" class="col-6">
                    <div style="background: #00C85126;border-radius: 18px 18px 18px 2px;font-size: 12px;color: #00C851;font-weight: 400; float: right; padding: 2px 4px 2px 4px;">-25%</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;text-decoration: line-through;">50,00 TL</span>
                </div>

                <div class="col-12">
                    <span style="color: #484848; font-weight: 500; font-size: 12px;">Nutella Kakaolu Fındık Kreması</span>
                </div>

                <div class="col-12">
                    <span style="color: #888888; font-weight: 400; font-size: 12px;">500 gr</span>
                </div>
            </div>

            <div class="row">
                <div style="margin-top: 5px;" class="col-12">
                    <div style="background-color: #65BAB01A;border-radius: 4px;padding: 6px;">
                        <img style="width: 20px;margin-right: 6px;" src="../../assets/img/barcode-icon.png">
                        <span style="font-weight: 400;font-size: 11px;color: #65BAB0;margin: 0;">Ürün Barkodunu Okut</span>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="col-12">
            <div style="display: inline-flex;align-items: center;overflow: auto;">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf1.jpg">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf2.jpg">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf3.jpg">
                <img style="width: 50%;margin-right: 15px;" src="../../assets/img/hf4.jpg">
            </div>
        </div> -->
    </div>
</div>

<app-footer></app-footer>