import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mycards',
  templateUrl: './mycards.component.html',
  styleUrls: ['./mycards.component.css']
})
export class MycardsComponent implements OnInit {

  userInfo
  testURLLink;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.userInfo = localStorage.getItem("userInfo")
    this.userInfo = JSON.parse(this.userInfo)

    this.testURLLink = this.sanitizer.bypassSecurityTrustResourceUrl(`https://mkolay.moneypay.com.tr/#/list?userToken=${this.userInfo.paymentToken}`);
  }

}
