import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-money-pay-otp-verification',
  templateUrl: './money-pay-otp-verification.component.html',
  styleUrls: ['./money-pay-otp-verification.component.css']
})
export class MoneyPayOtpVerificationComponent implements OnInit {

  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  countdownOtp: any = 10;
  interval: any;
  otpCodeForm: any
  cardNumber = localStorage.getItem('virtualOrCardId');
  basketId;
  phoneNumber = "+905417816981"

  constructor(private formBuilder: FormBuilder, private router: Router, public coreService: CoreService, private toastrService: ToastrService) {
    this.otpCountDown()


    this.otpCodeForm = this.formBuilder.group({
      code: this.formBuilder.control('', [Validators.required]),
    })
  }

  ngOnInit(): void {
    this.basketId = localStorage.getItem('basketId')
  }

  digitValidate(ele) {
    console.log(ele);
    ele.value = ele.value.replace(/[^0-9]/g, '');
  }

  tabChange(val) {
    let ele = document.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val].focus()
    } else if (ele[val - 1].value == '') {
      ele[val - 2].focus()
    }
  }

  otpCountDown() {
    clearInterval(this.interval)
    this.sendCode()
    let countdown = 60
    this.countdownOtp = countdown
    this.interval = setInterval(() => {
      this.countdownOtp--;
      if (this.countdownOtp <= 0) {
        clearInterval(this.interval)
        return;
      }
    }, 1000)

  }

  reSend() {
    this.codeInput.reset()
    this.sendCode()
    this.otpCountDown()
    var el = document.getElementById('c2');
    el.style.animationName = "test1";
    setTimeout(() => {
      el.style.animationName = "countdown"
    }, 1);
  }

  onCodeChanged(otpCode: string) {
    this.otpCodeForm.patchValue({ code: otpCode })
  }

  // this called only if user entered full code
  onCodeCompleted(otpCode: string) {
    this.otpCodeForm.patchValue({ code: otpCode })
    setTimeout(() => {
      //this.router.navigateByUrl("start-shopping")
      this.verifyCode()
    }, 1000);

  }

  sendCode() {

    var data = `act=mkolaypaymentsendotp&cardnumber=${this.cardNumber}&moneypayphone=${this.phoneNumber}&basketid=${this.basketId}&myhash=1231231233475638457`

    this.coreService.coreServiceFunc(data).subscribe(res => {
      console.log(res)
      if(res["statusCode"] == 1){
        this.toastrService.success(res["message"], "OTP")
      }
      
    }, (err => {
      console.log(err)
    }))

  }

  verifyCode() {
    console.log(this.otpCodeForm.value.code)

    var data = `act=mkolaypaymentverifyotp&cardnumber=${this.cardNumber}&moneypayphone=${this.phoneNumber}&moneypaycode=${this.otpCodeForm.value.code}&basketid=${this.basketId}&myhash=1231231233475638457`

    this.coreService.coreServiceFunc(data).subscribe(res => {
      console.log(res)
      if(res["statusCode"] != 1){
        this.toastrService.error(res["message"], "Hata")
      }
      else if (res["statusCode"] == 1){
        location.href = "/payment/money-pay/payment-options"
      }
      
    }, (err => {
      console.log(err)
    }))
  }

}
