import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.css']
})
export class StoreDetailComponent implements OnInit {
  
  nearStore: any = [];
  storeID;
  

  constructor(public coreService: CoreService, private route: ActivatedRoute) {

    this.storeID = this.route.snapshot.paramMap.get('storeID');
  }

  async ngOnInit() {
    await this.searchNearStoresByLatLong()
  }

  async searchNearStoresByLatLong() {
    var data = `act=magazadetay&magazano=${this.storeID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.nearStore = data["ResultObjects"][0]
      console.log(this.nearStore)

    })
  }

  

}
