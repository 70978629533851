import { Component, OnInit } from '@angular/core';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-shopping-history',
  templateUrl: './shopping-history.component.html',
  styleUrls: ['./shopping-history.component.css']
})
export class ShoppingHistoryComponent implements OnInit {

  shoppingHistoryList: any = []
  userInfo: any



  constructor(public coreService: CoreService) { }

  ngOnInit(): void {
    this.userInfo = localStorage.getItem("userInfo")
    this.userInfo = JSON.parse(this.userInfo)
    this.getShoppingHistory(this.userInfo)


  }


  getShoppingHistory(userInfo: any) {

    var data = `act=mkolayalisverislerim&module=magaza&cardId=${userInfo.cardId}&gsmId=${userInfo.gsmId}&myhash=1231231233475638457`

    this.coreService.coreServiceFunc(data).subscribe(response => {
      this.shoppingHistoryList = response
      // for (let index = 0; index < this.shoppingHistoryList.length; index++) {
      //   this.shoppingHistoryList[index]["transactionHeader"].date = this.convertDateFormat(this.shoppingHistoryList[index]["transactionHeader"].date)
      // }
      
      

    }, (err => {
      console.log(err)
    }))

  }

  convertDateFormat(date) {
    let objectDate = new Date(date);
    console.log(date)
    console.log(objectDate)


    let day = objectDate.getDate();
    console.log(day); // 23

    let month = objectDate.getMonth() + 1;
    console.log(month); // 8

    let year = objectDate.getFullYear();
    console.log(year); // 2022

    let format2 = `${day}/${month}/${year}`;
    return new Date(format2)
  }

}
