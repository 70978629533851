<div style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img style="width: 24px;margin-right: 8px;" routerLink="/login"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Giriş Yap</span>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" style="margin: 30px 0;">
    <div class="row">


        <h2 style="margin-bottom: 12px;">Telefonu Doğrula</h2>

        <span style="font-size: 16px; color: #000000;margin-bottom: 12px;">SMS ile gelen doğrulama kodunu kutucukların içine yaz!</span>

        <strong style="font-size: 16px; color: #000000;margin-bottom: 30px;">0{{phoneNumber | mask:'999 999 99 99'}}</strong>
        <div style="margin-bottom: 38px;">
            <code-input #codeInput [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onCodeCompleted($event)" class="otp-code-field">
            </code-input>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <button [disabled]="!otpCheck"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                type="button" class="btn btn-primary bg-color1  c-color8" (click)="verifyCode()">Gönder</button>
        </div>

        <div class="d-flex justify-content-between align-items-center" style="margin-top:16px">
            <div>
                <span class="c-color6" style="font-size: 14px;">Kodu almadın mı? </span> <span class="c-color1"
                    style="font-size: 14px; text-decoration: underline;" (click)="reSend()"> Tekrar
                    gönder</span>
            </div>
        
            <div id="countdown">
                <div id="countdown-number">{{countdownOtp}}</div>
                <svg viewBox="0 0 40 40">
                  <g transform="translate(20 20) rotate(-90)"
                    stroke-width="4" fill="none" stroke-linecap="round">
                    <circle id="c3" r="18"/>
                    <circle id="c2" r="18"/>
                  </g>
                </svg>
              </div>
        </div>

    </div>
</div>