<div style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/profile" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay’a Üye Ol</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div style="margin-top: 40px;" class="col-12 text-center">
            <img style="width: 138px;" src="../../assets/img/mkolay-logo.png">
        </div>

        <div style="margin-top: 10px;" class="col-12 text-center">
            <img style="width: 70px;" src="../../assets/img/down-logo.png">
        </div>

        <div style="margin-top: 10px;" class="col-12 text-center">
            <img style="width: 105px;" src="../../assets/img/money-logo.png">
        </div>

        <div style="margin-top: 30px;" class="col-12 text-center">
            <img style="width: 50px;" src="../../assets/img/loading.gif">
        </div>

        <div style="margin-top: 20px;" class="col-12 text-center">
            <h1 style="font-size: 16px;font-weight: 600;color: #000000;margin-top: 0px;margin-bottom: 15px;">Money uygulamasına yönlendiriliyorsun.</h1>

            <h2 style="font-size: 14px;font-weight: 400;color: #000000;margin-top: 0px;margin-bottom: 15px;line-height: 25px;">İşlemin tamamlandığında tekrar MKolay’a yönlendirileceksin.</h2>
        </div>
    </div>
</div>