import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  api = "";
  key: any = environment.encryptedKey;
  IV = environment.encryptedIV;

  option:any

  footerData = new Subject()

  constructor(public http: HttpClient, public analytics: AngularFireAnalytics) {
    //const API = 'https://magaza-iphone.migros.com.tr/wsmkolay/wsgateway.aspx';

    const API = environment.apiURL

    this.api = API
  }

  // ENCRYPTION USING CBC TRIPLE DES
  encryptUsingTripleDES(res: any, typeObj: boolean): string {
    const data = typeObj ? JSON.stringify(res) : res;
    const keyHex = CryptoJS.enc.Utf8.parse(this.key);
    const iv = CryptoJS.enc.Utf8.parse(this.IV);
    const mode = CryptoJS.mode.CBC;
    const encrypted = CryptoJS.TripleDES.encrypt(data, keyHex, { iv, mode });
    return encrypted.toString();
  }

  decryptUsingTripleDES(res: any, typeObj: boolean): string {
    const data = typeObj ? JSON.stringify(res) : res;
    
    const keyHex = CryptoJS.enc.Utf8.parse(this.key);
    const iv = CryptoJS.enc.Utf8.parse(this.IV);
    const mode = CryptoJS.mode.CBC;
    const decrypted = CryptoJS.TripleDES.decrypt(data, keyHex, { iv, mode });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  searchNearStoresByLatLong(){
    var data = "act=mkolay_getmagazalarall&myhash=21146545131232151321"
    const encryptedString = this.encryptUsingTripleDES(data,false)
    return encryptedString;
  }
  

  coreServiceFunc(bodyData) {
    console.log(bodyData)
    const encryptedString = this.encryptUsingTripleDES(bodyData,false)
    console.log(encryptedString)
    return this.http.post(`${this.api}`,
    encryptedString,
      {
        headers: new HttpHeaders({
        })
      })
  }

  testRXJS(data){
    this.footerData.next(data)
  }


  setOption(value: any) {
    this.option = value;
    console.log(this.option)
    //console.log("ilandetay", this.data)
  }

  getOption() {
    return this.option;
    
  }

  saveEvent(eventName){
    this.analytics.logEvent(eventName);
  }

  saveEventwithParameters(eventName,parameter){
    this.analytics.logEvent(eventName,{response_time: parameter})
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
    console.log(navigator.userAgent)

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
}


}
