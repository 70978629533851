import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';
declare var $

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.css']
})
export class PaymentOptionsComponent implements OnInit {

  isCollapsed = false;

  selectedPayment = null

  productList: any = [];
  productDetails;
  product;
  cardNumber = localStorage.getItem("virtualOrCardId")
  basketID;
  totalPrice;
  totalDiscount;
  totalQTY;

  storeName;
  storeID;
  basketDate

  hasValueableProduct = false;

  showJetKasa = 0;
  showMoneyPay = 0;
  showCreditCard = 0;

  constructor(private coreService:CoreService, private router: Router) { }

  ngOnInit(): void {
    this.storeName = localStorage.getItem('storeName')
    this.storeID = localStorage.getItem('storeId')
    this.basketID = localStorage.getItem('basketId')
    this.basketDate = new Date(localStorage.getItem('basketDate'))

    this.getPaymentOptions()
    this.getBasket()
  }


  async getBasket() {
    var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.productList = data["resultData"]["productList"]
      console.log(this.productList)
      this.productDetails = data["resultData"]
      for (let index = 0; index < this.productList.length; index++) {
        this.totalQTY = parseInt(this.productList[index]["quantity"])
        if (this.productList[index].isValuable == 1) {
          this.hasValueableProduct = true
        }
        this.productList[index].calcPrice = parseFloat(this.productList[index].calcPrice)
      }
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
    }, (err => {
      console.log(err)
    }))
  }


  async getPaymentOptions() {
    var data = `act=mkolaypaymentoptions&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457`

    this.coreService.coreServiceFunc(data).subscribe(async data => {
      console.log(data)
      console.log(this.hasValueableProduct)
      var array: any = data;
      var reData = {
        jetKasa: {},
        moneyPay: {},
        creditCard: {}
      }

      for (let index = 0; index < array?.length; index++) {
        if (array[index].type == "JETKASA") {
          reData.jetKasa = array[index]
        }
        if (array[index].type == "MONEYPAY") {
          reData.moneyPay = array[index]
        }
        if (array[index].type == "CREDITCARD") {
          reData.creditCard = array[index]
        }
      }
      console.log(reData)
      if (this.hasValueableProduct && reData.jetKasa["isEnabled"] == 1) {
        this.showJetKasa = reData.jetKasa["isEnabled"]
        // location.href = "/payment/jetkasa/scan-qr"
      }
      else if (this.hasValueableProduct && reData.jetKasa["isEnabled"] == 0) {
        this.showJetKasa = reData.jetKasa["isEnabled"]
      }

      else if (!this.hasValueableProduct) {
        this.showJetKasa = reData.jetKasa["isEnabled"]
        this.showMoneyPay = reData.moneyPay["isEnabled"]
        this.showCreditCard = reData.creditCard["isEnabled"]
      }

      if(this.showJetKasa && !this.showCreditCard && !this.showMoneyPay){
        this.router.navigateByUrl("/payment/jetkasa/scan-qr")
      }

      console.log("JETKASA: " + this.showJetKasa)
      console.log("MONEYPAY: " + this.showMoneyPay)
      console.log("CREDITCARD: " + this.showCreditCard)

      var paymentOptionList = {
        "jetKasa" : this.showJetKasa,
        "moneyPay" : this.showMoneyPay,
        "creditCard":this.showCreditCard
      }

      localStorage.setItem("paymentOptionList" , JSON.stringify(paymentOptionList))

    })
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  redirectSelectedPayment() {
    console.log(this.selectedPayment)

    if (this.selectedPayment == 1) {
      this.router.navigateByUrl("/payment/jetkasa/scan-qr")

    }
    else if (this.selectedPayment == 2) {
    
      localStorage.setItem("paymentFlow" ,this.selectedPayment)
      if (!localStorage.getItem("userInfo")) {
        console.log("girdi")
        $('#moneyLoginModal').modal('show')
       
      }
      else {
        location.href = "/payment/money-pay/scan-qr"
      }
    }
    else if (this.selectedPayment == 3) {
      
      localStorage.setItem("paymentFlow" , this.selectedPayment)
      if (!localStorage.getItem("userInfo")) {
       
        $('#moneyLoginModal').modal('show')
      }
      else {
        location.href = "/payment/credit-card/scan-qr"
      }
    }

  }

}
