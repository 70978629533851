<div *ngIf="step == 1" style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img (click)="onNavigatePaymentOptions()" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">Jet Kasa ile Öde</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step == 2" style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img (click)="step = 1" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">QR Kodu Okut</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step == 1" class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <img style="width: 240px;" src="./../../assets/img/odeme-oncesi-image.png">
            <h2 class="c-color5" style="font-size: 20px;font-weight: 600;margin-top: 20px;">Ödeme Öncesi
                Bilgilendirme</h2>

            <p class="c-color5" style="line-height: 24px;font-size: 16px;font-weight: 400;margin-top: 15px;">
                Jet kasa üzerindeki <strong style="font-weight: 600;">MKolay butonuna tıklayıp, çıkan QR kodu
                    okutarak</strong> ödeme işlemine geçebilirsin.</p>
        </div>

        <div class="col-12 text-center">
            <button (click)="step = 2"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Qr Kodu Okut</button>
        </div>
    </div>
</div>

<div *ngIf="step == 2" class="bg-color7" style="width: 100%;height: 100%;position: fixed;">
    <div class="container-fluid">
        <div class="row">
            <div style="margin-top: 120px;" class="col-12 text-center">
                <div class="scandit-wrapper">
                    <div class="cam-border top-left"></div>
                    <div class="cam-border top-right"></div>
                    <div class="cam-border bottom-left"></div>
                    <div class="cam-border bottom-right"></div>
                    <div class="animated-scanner-line"></div>
                <scandit-sdk-barcode-picker [enableCameraSwitcher]="false"
                [enableTorchToggle]="false" #picker [playSoundOnScan]="true"
                [vibrateOnScan]="true" [scanSettings]="activeSettingsQR" [singleImageModeSettings]="singleImageSettings" [videoFit]="videoFit" (scan)="onScan($event)" [guiStyle]="scannerGuiStyle"
                    [style.height]="'80vw'" [style.width]="'100%'" [style.display]="'block'">
                </scandit-sdk-barcode-picker>
                </div>
            </div>

            <!-- <div style="margin-top: 20px;" class="col-12 text-center">
                <button (click)="payWithQR()" [disabled]="isDisable" style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;color: white; border-radius: 8px;margin-top: 30px;"
                        type="button" class="btn btn-primary bg-color1">Test QR</button>
            </div> -->
        </div>
    </div>
</div>