import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  phoneNumber = "";
  step = 1;

  constructor(private router:Router, private coreService:CoreService) { }

  ngOnInit(): void {
    console.log(this.phoneNumber.length)
  }

  goOtpProcess(){
    var data = `act=mkolay_login_start&gsm=${this.phoneNumber}`
    this.coreService.coreServiceFunc(data).subscribe(res => {
      this.router.navigateByUrl(`/otp-process/${this.phoneNumber}`)
    })
  
  }

}
