<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/back-icon-green.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay Mağaza</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step == 1" class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <!-- <img style="width: 80px;" src="../../"> -->
            <div style="width: 80px;margin: auto;">
                <ng-lottie width="100%" [options]="migrosPinOptions"></ng-lottie>
            </div>

            <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Konum İzinlerini <br> Açmayı
                Unutma</h2>

            <!-- <p class="c-color4" style="line-height: 24px;font-size: 14px;font-weight: 400;margin-top: 15px;">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
        </div>

        <div class="col-12 text-center">
            <button (click)="getLocation()"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                type="button" class="btn btn-primary bg-color1 c-color8">Konuma İzin Ver!</button>
        </div>

        <!-- <div class="col-12 text-center">
            <button routerLink="/search-with-qr"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                type="button" class="btn btn-primary c-color4 border-color4">QR Kod ile Devam Et</button>
        </div> -->
    </div>
</div>

<div *ngIf="step == 2" class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <div style="width: 80px;margin: auto;">
                <ng-lottie width="100%" [options]="migrosPinOptions"></ng-lottie>
            </div>
            <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Size En Yakın <br> Migros
                Mağazası <br> Aranıyor!</h2>
        </div>
    </div>
</div>

<div *ngIf="step == 3" class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <div style="width: 80px;margin: auto;">
                <ng-lottie width="100%" [options]="migrosPinOptions2"></ng-lottie>
            </div>
            <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Lokasyonunuza yakın <br>
                mağaza bulunamadı!</h2>
        </div>

        <div class="col-12 text-center">
            <button routerLink="/search"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Arama Yaparak Bul</button>
        </div>

        <!-- <div class="col-12 text-center">
            <button routerLink="/near-stores" 
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;background-color: #eff8f7 !important; border-radius: 8px;margin-top: 20px;"
                type="button" class="btn btn-primary c-color1">
                <div style="display: inline-flex;align-items: center;width: 100%;">
                    <div style="display: inline-flex;align-items: center;width: 92%;">
                        <img style="width: 34px;margin-right: 8px;" src="./../../assets/img/modal-icon.png"><h3 style="margin-bottom: 0;font-weight: 500;">Mağaza Listesi</h3> 
                    </div>
                    <div>
                        <img style="width: 24px;" src="../../assets/img/next-icon-green.png">
                    </div>
                </div>
            </button>
        </div> -->

    </div>
</div>




<!-- Check Location Permission Modal -->
<div class="modal fade" id="checkLocationPermissionModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/modal-icon.png">

                            <h4 class="c-color5"
                                style="margin-top: 20px;line-height: 30px;font-size: 19px;font-weight: 500;">
                                Konumun İzinlerini Açmayı Unutma!</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button data-bs-dismiss="modal"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Vazgeç</button>
                        </div>
                        <div class="col-6">
                            <button (click)="getLocation()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">İzin Ver</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Denied Permission Modal -->
<div class="modal fade" id="deniedPermissionModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static"
data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../assets/img/modal-icon.png">

                            <h4 class="c-color5"
                                style="margin-top: 20px;line-height: 30px;font-size: 19px;font-weight: 500;">
                                Telefon Ayarlarınızdan Konum İzinlerini Açınız!</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button data-bs-dismiss="modal" (click)="getLocation()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Tamam</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="androidLocationInfoModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static"
data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
              
                <div class="">
                    <div class="d-flex justify-content-between ">
                        <h4 class="c-color5" style="line-height: 30px;font-size: 16px;font-weight: 500;">
                            Android için Konum Bulma Servislerini Açın!</h4>
                            <img  (click)="pageReload()" style="width: 24px;height: 24px;" src="../../assets/img/close-icon.png">  
                    </div>

                    <div class="d-flex justify-content-start  align-items-center" style="margin-top:8px">
                        <img src="/assets/img/iosicon1.png" class="me-3" width="40px" height="40px" alt="">
                        <p class="mb-0">1. Telefonun Ayarlarını Açın</p>
                    </div>
                    <div class="d-flex justify-content-start   align-items-center" style="margin-top:16px">
                        <img src="/assets/img/iosicon2.png" class="me-3" width="40px"  height="40px" alt="">
                        <p class="mb-0">2. Gizliliği Seç</p>
                    </div>
                    <div class="d-flex justify-content-start   align-items-center" style="margin-top:16px">
                        <img src="/assets/img/iosicon3.png" class="me-3" width="40px"  height="40px" alt="">
                        <p class="mb-0">3. Lokasyon Servisini Seç</p>
                    </div>
                    <div class="d-flex justify-content-start   align-items-center" style="margin-top:16px">
                        <img src="/assets/img/iosicon4.png" class="me-3" width="40px"  height="40px" alt="">
                        <p class="mb-0">4. Konum Servisini Aç</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12">
                        <button  (click)="pageReload()"
                            style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                            type="button" class="btn btn-primary bg-color1  c-color8" >Tamam</button>
                    </div>
                </div>
         
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="iosLocationInfoModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static"
data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
              
                    <div class="">
                        <div class="d-flex justify-content-between ">
                            <h4 class="c-color5" style="line-height: 30px;font-size: 16px;font-weight: 500;">
                                Iphone’unuz için Konum Bulma Servislerini Açın!</h4>
                                <img  (click)="pageReload()" style="width: 24px;height: 24px;" src="../../assets/img/close-icon.png">  
                        </div>

                        <div class="d-flex justify-content-start  align-items-center" style="margin-top:8px">
                            <img src="/assets/img/iosicon1.png" class="me-3" width="40px" height="40px" alt="">
                            <p class="mb-0">1. Telefonun Ayarlarını Açın</p>
                        </div>
                        <div class="d-flex justify-content-start   align-items-center" style="margin-top:16px">
                            <img src="/assets/img/iosicon2.png" class="me-3" width="40px"  height="40px" alt="">
                            <p class="mb-0">2. Gizliliği Seç</p>
                        </div>
                        <div class="d-flex justify-content-start   align-items-center" style="margin-top:16px">
                            <img src="/assets/img/iosicon3.png" class="me-3" width="40px"  height="40px" alt="">
                            <p class="mb-0">3. Lokasyon Servisini Seç</p>
                        </div>
                        <div class="d-flex justify-content-start   align-items-center" style="margin-top:16px">
                            <img src="/assets/img/iosicon4.png" class="me-3" width="40px"  height="40px" alt="">
                            <p class="mb-0">4. Konum Servisini Aç</p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button (click)="pageReload()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Tamam</button>
                        </div>
                    </div>
             
            </div>
        </div>
    </div>
</div>