<div style="box-shadow: 0px 4px 8px #0000000D;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img (click)="onNavigatePaymentOptions()" style="width: 24px;margin-right: 8px;"
                        src="../../assets/img/close-icon.png">
                    <span *ngIf="step != 3" class="c-color4" style="font-size: 16px;font-weight: 500;">Mobil Ödeme Noktası</span>
                    <span *ngIf="step == 3" class="c-color4" style="font-size: 16px;font-weight: 500;">Ödeme</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step == 1" class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <img style="width: 240px;" src="../../../../assets/img/credit-card-info-image.png">

            <p class="c-color5" style="line-height: 24px;font-size: 16px;font-weight: 400;margin-top: 30px;">
                Qr kodu <strong style="font-weight: 600;">Mobil Ödeme Noktasında</strong> <br> bulunan okutucuya okutunuz.</p>
        </div>

        <div class="col-12 text-center">
            <button (click)="step = 2"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;margin-top: 40px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Qr Kodu Okut</button>
        </div>
    </div>
</div>

<div *ngIf="step == 2" class="container-fluid">
    <div class="row">
        <div style="margin-top: 40px;" class="col-12 text-center">
            <h2 class="c-color5" style="font-weight: 600;font-size: 20px;margin: 0;">Qr göster ve öde!</h2>
            <h4 class="c-color5" style="font-weight: 400;font-size: 16px;margin-top: 10px;">Qr kodunu ödeme noktasına okut.</h4>
        </div>
    </div>
    <div class="row">
        <!-- <div style="margin-top: 50px;" class="col-12 text-center">
            <div class="scandit-wrapper">
                <div class="cam-border top-left"></div>
                <div class="cam-border top-right"></div>
                <div class="cam-border bottom-left"></div>
                <div class="cam-border bottom-right"></div>
                <qrcode [qrdata]="qrData" [margin]="10" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
            
        </div> -->
        <div class="qr-code-container mx-auto">
            <div class="qr-corner qr-corner1"></div>
            <div class="qr-corner qr-corner2"></div>
            <div class="qr-corner qr-corner3"></div>
            <div class="qr-corner qr-corner4"></div>
            <div class="qr-code">
                <qrcode [qrdata]="qrData"  [width]="215" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step == 3" style="height: 100%;" class="container-fluid">
    <div style="height: 100%;" class="row">
        <div style="margin-top: 40px;" class="col-12 text-center">
            <iframe width="100%" height="100%" [src]="testURLLink"></iframe>
        </div>
    </div>
</div>

<!-- Add Bag Modal -->
<div class="modal fade" id="addBagModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div style="padding: 0px !important;" class="container-fluid">
                    <div class="row">
                        <div style="margin-top: 20px;" class="col-md-12 text-center">
                            <img style="width: 82px;" src="../../../../assets/img/carrier-bag-icon.png">

                            <h4 *ngIf="isAddedBasket == 0" class="c-color5"
                                style="margin-top: 20px;line-height: 30px;font-size: 16px;font-weight: 500;">
                                Poşet Ekleyiniz!</h4>
                            <h4 *ngIf="isAddedBasket == 1" class="c-color5"
                                style="margin-top: 20px;line-height: 30px;font-size: 16px;font-weight: 500;">
                                Poşet Sayısını Seçiniz!</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="isAddedBasket == 1" style="margin-bottom: 20px;" class="col-12 text-center">
                            <div
                                style="height:52px;border-radius: 26px;border: 1px solid #EEEEEE;margin: auto;display: inline-flex;align-items: center;">
                                <div>
                                    <img *ngIf="productDetails?.packageData?.quantity < 2"
                                        (click)="decreaseBagToBasket()" style="width: 44px;margin-left: 2px;"
                                        src="../../assets/img/delete-icon.png">
                                    <img *ngIf="productDetails?.packageData?.quantity >= 2"
                                        (click)="decreaseBagToBasket()" style="width: 44px;margin-left: 2px;"
                                        src="../../assets/img/mines-icon.png">
                                </div>
                                <div style="display: grid;margin-left: 10px;margin-right: 10px;">
                                    <span class="c-color1"
                                        style="font-size: 20px;font-weight: 500;margin-bottom: 2px;">{{productDetails?.packageData?.quantity}}</span>
                                    <span class="c-color4" style="font-size: 10px;font-weight: 400;">Poşet eklendi</span>
                                </div>
                                <div class="border-color1"
                                    style="border: 1px solid;border-radius: 50%;width: 45px;height: 45px;display: inline-flex;align-items: center;margin-right: 2px;">
                                    <div style="width: 100%;">
                                        <img (click)="increaseBagToBasket()" style="width: 18px;"
                                            src="../../assets/img/plus-icon.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-color2" style="border-radius: 8px;padding: 12px;">
                        <div class="row">
                            <div class="col-8 text-start">
                                <div style="display: inline-flex;align-items: center;">
                                    <div
                                        style="width: 30px;height: 30px;border-radius: 50%;background-color: white;display: inline-flex;align-items: center;justify-content: center;margin-right: 10px;">
                                        <img src="../../assets/img/tax-active-icon.png" style="width: 20px;">
                                    </div>
        
                                    <span class="c-color1" style="font-size: 14px;font-weight: 500;">Güncel Sepet Tutarı:</span>
                                </div>
                            </div>
                            <div style="display: inline-grid;align-items: center;"
                                class="col-4 text-end">
                                <span class="c-color5" style="font-size: 16px;font-weight: 600">₺{{totalPriceTwo + totalDiscount | number :
                                    '1.2-2'}}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-6">
                            <button (click)="setZeroBagToBasket()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Vazgeç</button>
                        </div>
                        <div class="col-6">
                            <button *ngIf="isAddedBasket == 1" (click)="goCreditCardSelectionPage()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Devam Et</button>

                            <button *ngIf="isAddedBasket == 0" (click)="increaseBagToBasket()"
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;margin-top: 20px;"
                                type="button" class="btn btn-primary bg-color1  c-color8">Poşet Ekle</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>