import { Component, OnInit } from '@angular/core';
import { Barcode, BarcodePicker, ScanResult, ScanSettings, SingleImageModeSettings } from 'scandit-sdk';

@Component({
  selector: 'app-search-store-qr',
  templateUrl: './search-store-qr.component.html',
  styleUrls: ['./search-store-qr.component.css']
})
export class SearchStoreQrComponent implements OnInit {

  public videoFitCover: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.COVER;
  public videoFit: BarcodePicker.ObjectFit = this.videoFitCover;
  public scannerGuiStyle: BarcodePicker.GuiStyle = BarcodePicker.GuiStyle.VIEWFINDER;
  public scannedCodes: Barcode[] = [];
  public scannedBarcodes: Barcode[] = [];
  public settingsQR: ScanSettings;
  public settings128: ScanSettings;
  public activeSettingsQR: ScanSettings;
  public activeSettingsBarcode: ScanSettings;

  public singleImageSettings: SingleImageModeSettings;

  barcodeInput;

  constructor() {
    this.settingsQR = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.QR]
    });

    this.settings128 = new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.CODE128, Barcode.Symbology.EAN13, Barcode.Symbology.EAN8, Barcode.Symbology.CODE39, Barcode.Symbology.CODE93, Barcode.Symbology.PDF417, Barcode.Symbology.AZTEC],
      codeDuplicateFilter: 2000
    });

    this.activeSettingsQR = this.settingsQR;
    this.activeSettingsBarcode = this.settings128

    this.singleImageSettings = {
      desktop: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
      mobile: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
    };
  }

  ngOnInit(): void {
  }

  onScan(result: ScanResult) {
    if (this.scannedCodes.length == 0) {
      this.scannedCodes = this.scannedCodes.concat(result.barcodes);
      console.log(this.scannedCodes[0].data)
      location.href = `/store/${this.scannedBarcodes[0].data}`
    }
  }

  goProductPage(){
    console.log(this.barcodeInput)
    location.href = `/store/${this.barcodeInput}`
  }

}
