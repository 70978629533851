import { Component, OnInit } from '@angular/core';
import { CoreService } from '../services/core.service';


@Component({
  selector: 'app-search-store',
  templateUrl: './search-store.component.html',
  styleUrls: ['./search-store.component.css']
})
export class SearchStoreComponent implements OnInit {

  public lat;
  public lng;
  nearStoreList: any = [];
  nearStore: any = [];

  searchText;

  constructor(public coreService: CoreService) { }

  async ngOnInit() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          console.log(this.lat);
          localStorage.setItem("lat",this.lat)
          localStorage.setItem("lng",this.lng)
          localStorage.setItem('locationPermission', "true")
        }
      },
        (error: any) => {
          console.log(error)
          localStorage.removeItem("lat")
          localStorage.removeItem("lng")
          localStorage.removeItem('locationPermission')
          // location.href = "/check-location"
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    await this.searchNearStoresByLatLong()

  }

  calculateDistance(lat1,long1,lat2,long2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (long2-long1) * Math.PI/180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }

  async searchNearStoresByLatLong(){
    const lat1 = localStorage.getItem("lat");
    const long1 = localStorage.getItem("lng");
    var data = "act=mkolay_getmagazalarall&myhash=21146545131232151321"

    this.coreService.coreServiceFunc(data).subscribe(data => {
      
      this.nearStoreList = data["ResultObjects"]
      console.log(this.calculateDistance(lat1,long1,data["ResultObjects"][0]["XKoordinat"],data["ResultObjects"][0]["YKoordinat"]))

      for (let index = 0; index < this.nearStoreList.length; index++) {
        this.nearStoreList[index]["DistanceKM"] = this.calculateDistance(lat1,long1,data["ResultObjects"][index]["XKoordinat"],data["ResultObjects"][index]["YKoordinat"]) / 1000
      }

      
      this.nearStoreList = data["ResultObjects"].sort((a,b) => {
        return a.DistanceKM - b.DistanceKM;
      })

      this.nearStore = this.nearStoreList[0]
      console.log(this.nearStore["XKoordinat"])

      this.nearStoreList.splice(0,1)
      console.log(this.nearStoreList)
      
    })
  }

}
