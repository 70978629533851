import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasketComponent } from './basket/basket.component';
import { LoginComponent } from './login/login.component';
import { NearStoresComponent } from './near-stores/near-stores.component';
import { CreditCardFailComponent } from './payment/credit-card/credit-card-fail/credit-card-fail.component';
import { CreditCardSuccessComponent } from './payment/credit-card/credit-card-success/credit-card-success.component';
import { PayWithCreditCardComponent } from './payment/credit-card/pay-with-credit-card/pay-with-credit-card.component';
import { JetkasaScanQrComponent } from './payment/jetkasa/jetkasa-scan-qr/jetkasa-scan-qr.component';
import { JetkasaSuccessComponent } from './payment/jetkasa/jetkasa-success/jetkasa-success.component';
import { MoneyPayOtpVerificationComponent } from './payment/money-pay/money-pay-otp-verification/money-pay-otp-verification.component';
import { MoneyPayPaymentOptionsComponent } from './payment/money-pay/money-pay-payment-options/money-pay-payment-options.component';
import { PayWithMoneyPayComponent } from './payment/money-pay/pay-with-money-pay/pay-with-money-pay.component';
import { ProductComponent } from './product/product.component';
import { ProfileComponent } from './profile/profile.component';
import { ScanAddProductComponent } from './scan-add-product/scan-add-product.component';
import { ScanDeleteProductComponent } from './scan-delete-product/scan-delete-product.component';
import { SearchStoreQrComponent } from './search-store-qr/search-store-qr.component';
import { SearchStoreComponent } from './search-store/search-store.component';
import { ShoppingHistoryDetailComponent } from './shopping-history-detail/shopping-history-detail.component';
import { ShoppingHistoryComponent } from './shopping-history/shopping-history.component';
import { SignupComponent } from './signup/signup.component';
import { StoreDetailComponent } from './store-detail/store-detail.component';
import { StoreLocationCheckComponent } from './store-location-check/store-location-check.component';
import { StoreMapsComponent } from './store-maps/store-maps.component';
import { StoreComponent } from './store/store.component';
import { WelcomeWizardComponent } from './welcome-wizard/welcome-wizard.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { OtpProcessComponent } from './otp-process/otp-process.component';
import { ClearCacheComponent } from './clear-cache/clear-cache.component';
import { IsLoginGuard } from './guards/is-login.guard';
import { PaymentOptionsComponent } from './payment/payment-options/payment-options.component';
import { MycardsComponent } from './mycards/mycards.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { AddProductSpecialOffersComponent } from './add-product-special-offers/add-product-special-offers.component';


const routes: Routes = [
  { path: "", component: WelcomeComponent },
  { path: "test", component: ClearCacheComponent },
  { path: "welcome-wizard", component: WelcomeWizardComponent },
  { path: "check-location", component: StoreLocationCheckComponent },
  { path: "store-maps", component: StoreMapsComponent },
  { path: "store-detail/:storeID", component: StoreDetailComponent },
  { path: "search", component: SearchStoreComponent },
  { path: "search-with-qr", component: SearchStoreQrComponent },
  { path: "near-stores", component: NearStoresComponent },
  { path: "store/:storeID", component: StoreComponent },
  { path: "scan-add-product", component: ScanAddProductComponent },
  { path: "scan-delete-product", component: ScanDeleteProductComponent },
  { path: "scan-delete-product/:barcodeID", component: ScanDeleteProductComponent },
  { path: "product/:barcodeID", component: ProductComponent },
  { path: "basket", component: BasketComponent },
  { path: "profile", component: ProfileComponent },
  { path: "shopping-history", component: ShoppingHistoryComponent },
  { path: "shopping-history/:shoppingID", component: ShoppingHistoryDetailComponent },
  { path: "login", component: LoginComponent, canActivate: [IsLoginGuard] },
  { path: "signup", component: SignupComponent },
  { path: "payment/payment-options", component: PaymentOptionsComponent },
  { path: "payment/jetkasa/scan-qr", component: JetkasaScanQrComponent },
  { path: "payment/jetkasa/success", component: JetkasaSuccessComponent },
  { path: "payment/credit-card/scan-qr", component: PayWithCreditCardComponent },
  { path: "payment/credit-card/success", component: CreditCardSuccessComponent },
  { path: "payment/credit-card/fail", component: CreditCardFailComponent },
  { path: "payment/money-pay/scan-qr", component: PayWithMoneyPayComponent },
  { path: "payment/money-pay/payment-options", component: MoneyPayPaymentOptionsComponent },
  { path: "payment/money-pay/otp-verification", component: MoneyPayOtpVerificationComponent },
  { path: "otp-process", component: OtpProcessComponent, canActivate: [IsLoginGuard]},
  { path: "otp-process/:phone", component: OtpProcessComponent, canActivate: [IsLoginGuard]},
  { path: "saved-cards", component: MycardsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
