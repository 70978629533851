<footer class="footer mt-auto  bg-light">
    <div class="menu-row">
        <div (click)="checkHome()" class="menu-item">
            <div class="icon">
                <img *ngIf="isHomepageActive" src="../../../assets/img/home-active-icon.png">
                <img *ngIf="!isHomepageActive" src="../../../assets/img/home-icon.png">
            </div>
            <span *ngIf="isHomepageActive" class="c-color1">Anasayfa</span>
            <span *ngIf="!isHomepageActive" style="color: black;">Anasayfa</span>
        </div>
        <div class="menu-item chart-item">
            <div routerLink="/basket" class="icon">
                <img *ngIf="isBasketActive && productList?.length == 0" src="../../../assets/img/footer-basket-active-icon.png">
                <img *ngIf="!isBasketActive && (productList?.length == 0 || productList == null )" src="../../../assets/img/footer-basket-icon.png">
                <div  *ngIf="productList?.length > 0">
                    <div style="position: absolute;left: 50%;top: 1px;width: 20px;background: black;color: white;border-radius: 50%;display: grid;align-items: center;">
                        <span style="font-size: 9px;">{{productList?.length}}</span>
                    </div>
                    <img *ngIf="isBasketActive" src="../../../assets/img/footer-basket-active-icon.png">
                    <img *ngIf="!isBasketActive" src="../../../assets/img/footer-basket-active-icon.png">
                </div>

            </div>
            <span routerLink="/basket"  [ngClass]="{'c-color1': isBasketActive}" *ngIf="productList?.length == 0 || productList == null ">Sepet</span>
            <span class="c-color5" style="font-weight: 500;" *ngIf="productList?.length > 0 && !isBasketActive">{{totalPrice | number : '1.2-2'}} TL</span>
            <span class="c-color1" style="font-weight: 500;" *ngIf="productList?.length > 0 && isBasketActive">{{totalPrice | number : '1.2-2'}} TL</span>
        </div>
        <div class="menu-item">
            
                <div class="icon">
                    <img *ngIf="isProfileActive" routerLink="/profile" src="../../../assets/img/user-active-icon.png">
                    <img *ngIf="!isProfileActive" routerLink="/profile" src="../../../assets/img/user-icon.png">
                </div>
                <span class="c-color1" *ngIf="isProfileActive">Hesap</span>
                <span *ngIf="!isProfileActive">Hesap</span>
        </div>
    </div>
</footer>