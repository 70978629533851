import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-card-fail',
  templateUrl: './credit-card-fail.component.html',
  styleUrls: ['./credit-card-fail.component.css']
})
export class CreditCardFailComponent implements OnInit {

  storeID = localStorage.getItem("storeId")

  constructor() { }

  ngOnInit(): void {
  }

}
