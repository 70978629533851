<div *ngIf="step == 1">
    <div style="border-bottom: 1px solid #00000026;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div style="margin-top: 20px;margin-bottom: 30px;display: inline-flex;align-items: center;">
                        <img routerLink="/profile" style="width: 24px;margin-right: 8px;"
                            src="../../assets/img/back-icon-green.png">
                        <span class="c-color4" style="font-size: 16px;font-weight: 500;">Giriş Yap</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid">
        <div class="row">
            <div style="margin-top:30px ;margin-bottom:30px" class="col-12 text-start">
                <h1 class="c-color5" style="font-size: 16px;font-weight: 600;margin-top: 0px;margin-bottom: 0px;">MKolay’a Hoş
                    Geldin</h1>
                <h1 class="c-color6" style="font-size: 14px;font-weight: 400;margin-top: 0px;margin-bottom: 0px;">Telefon numaranı girerek Money üyeliğin ile giriş yapabilirsin!                </h1>
            </div>
    
            <div style="margin-bottom: 30px;position: relative;" class="col-12">
                <input [(ngModel)]="phoneNumber" mask="000 000 00 00" type="text" [ngStyle]="{'border': phoneNumber ? '2px solid #65BAB0' : ''}" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                    style="height: 50px;background: white;border: 1px solid #EEEEEE;border-radius: 4px;"
                    placeholder="(xxx)xxx xx xx" class="form-control"  aria-label="default input example">
                    <img alt="close" *ngIf="phoneNumber" (click)="phoneNumber = null" class="remove-input-btn" src="../../assets/img/close-icon.png">
            </div>
    
            <div class="col-12">
                <button [disabled]="!phoneNumber"
                    style="width: 100%;height: 50px;font-size: 16px;font-weight: 500; border-radius: 8px;"
                    type="button" class="btn btn-primary bg-color1  c-color8" (click)="goOtpProcess()">Giriş Yap</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step == 2">
    
</div>
