import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CoreService } from '../services/core.service';

declare const google: any;
declare var $;

@Component({
  selector: 'app-store-maps',
  templateUrl: './store-maps.component.html',
  styleUrls: ['./store-maps.component.css']
})
export class StoreMapsComponent implements OnInit,AfterViewInit {

  map: any;
  @ViewChild('mapElement') mapElement: any;

  public lat;
  public lng;
  nearStoreList: any = [];
  nearStore: any = [];

  constructor(public coreService: CoreService) { }

  ngOnInit(): void {
    

    
  }

  async ngAfterViewInit(){

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          console.log(this.lat);
          localStorage.setItem("lat",this.lat)
          localStorage.setItem("lng",this.lng)
          localStorage.setItem('locationPermission', "true")
        }
      },
        (error: any) => {
          console.log(error)
        });
    } else {
      alert("Geolocation is not supported by this browser.");
    }


    await this.searchNearStoresByLatLong()

  }

  calculateDistance(lat1,long1,lat2,long2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (long2-long1) * Math.PI/180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }

  async searchNearStoresByLatLong(){
    const lat1 = localStorage.getItem("lat");
    const long1 = localStorage.getItem("lng");
    var data = "act=mkolay_getmagazalarall&myhash=21146545131232151321"

    this.coreService.coreServiceFunc(data).subscribe(data => {
      
      this.nearStoreList = data["ResultObjects"]
      console.log(this.calculateDistance(lat1,long1,data["ResultObjects"][0]["XKoordinat"],data["ResultObjects"][0]["YKoordinat"]))

      for (let index = 0; index < this.nearStoreList.length; index++) {
        this.nearStoreList[index]["DistanceKM"] = this.calculateDistance(lat1,long1,data["ResultObjects"][index]["XKoordinat"],data["ResultObjects"][index]["YKoordinat"]) / 1000
      }

      
      this.nearStoreList = data["ResultObjects"].sort((a,b) => {
        return a.DistanceKM - b.DistanceKM;
      })

      this.nearStore = this.nearStoreList[0]
      console.log(this.nearStore["XKoordinat"])
      this.drawMapsAndMarkerStore(this.nearStore["XKoordinat"], this.nearStore["YKoordinat"])

      
    })
  }

  async drawMapsAndMarkerStore(lat,lng){
    var options = {
      zoom: 18,
      center: {
        lat: lat, 
        lng: lng,
      },
      clickableIcons: true,
      disableDefaultUI: !0
    }

    var map = new google.maps.Map(document.getElementById("map"), options)

    new google.maps.Marker({
      position: {
        lat: lat, 
        lng: lng,
      },
      map,
      title: "Store",
      icon: "../../assets/img/migros-marker.png"
    });

    new google.maps.Marker({
      position: {
        lat: parseInt(localStorage.getItem("lat")),
        lng: parseInt(localStorage.getItem("lng")),
      },
      map,
      title: "User",
      icon: "../../assets/img/user-navigation-icon.png"
    });

    console.log(map)
  }

}
