import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-shopping-history-detail',
  templateUrl: './shopping-history-detail.component.html',
  styleUrls: ['./shopping-history-detail.component.css']
})
export class ShoppingHistoryDetailComponent implements OnInit {
  shoppingID:any
  userInfo:any
  shoppingHistoryList:any = []
  shoppingDetail:any
 
  constructor(private route:ActivatedRoute, private coreService:CoreService) { }

  ngOnInit(): void {
    this.userInfo = localStorage.getItem("userInfo")
    this.userInfo = JSON.parse(this.userInfo)
    this.shoppingID = this.route.snapshot.paramMap.get('shoppingID');
    this.getShoppingDetail(this.userInfo, this.shoppingID)
  
  }

  getShoppingDetail(userInfo:any, shoppingID:any){
    var data = `act=mkolayalisverislerim&module=magaza&cardId=${userInfo.cardId}&gsmId=${userInfo.gsmId}&myhash=1231231233475638457`
      
    this.coreService.coreServiceFunc(data).subscribe(response => {
      console.log(response)
      this.shoppingHistoryList = response
      this.shoppingDetail = this.shoppingHistoryList.filter((p:any) => p.transactionHeader?.transactionId == shoppingID)
      this.shoppingDetail = this.shoppingDetail[0]
      console.log(this.shoppingDetail)
        
    }, (err => {
      console.log(err)
    }))
  
  }

}
