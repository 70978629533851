<div style="border-bottom: 1px solid #00000026;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 20px;margin-bottom: 20px;display: inline-flex;align-items: center;">
                    <img routerLink="/store/{{storeID}}" style="width: 24px;margin-right: 8px;" src="../../assets/img/back-icon-green.png">
                    <span class="c-color4" style="font-size: 16px;font-weight: 500;">MKolay</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div style="margin-top: 30px;" class="col-12 text-center">
            <img style="width: 156px;" src="./../../assets/img/payment-success-image.png">
            <h2 class="c-color5" style="font-size: 20px;font-weight: 500;margin-top: 20px;">Alışverişiniz başarılı
                bir <br> şekilde tamamlandı!</h2>
        </div>

        <div class="col-12 text-center">
            <button routerLink="/store/{{storeID}}"
                style="width: 100%;height: 50px;font-size: 16px;font-weight: 500;border-radius: 8px;margin-top: 40px;"
                type="button" class="btn btn-primary bg-color1  c-color8">Anasayfa'ya Dön</button>
        </div>
    </div>
</div>

<app-footer></app-footer>

<div class="modal fade" id="rating" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body" style="padding:16px">
                <div >
                    <div class="row w-100 mx-auto">
                        <div class="col-md-12 px-0 text-center">
                            <img style="width: 82px; margin-top: 12px;margin-bottom: 16px;" src="../../assets/img/success-rating.png">

                            <h4 style="color: #202020;line-height: 30px;font-size: 16px;font-weight: 500;margin-bottom: 20px;">
                            Alışveriş deneyiminizi değerlendirmek ister misiniz?</h4>

                            <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 24px;">
                                <img width="48" class="p-1" *ngFor="let star of stars; let i = index" (click)="setRating(i)" [src]="star.url" alt="star empty">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <button data-bs-dismiss="modal" class="w-100"  (click)="completePayment()"
                                style="margin-right: 15px;width: 100%;height: 50px;font-size: 16px;font-weight: 600;border: 1px solid;background-color: white !important; border-radius: 8px;"
                                type="button" class="btn btn-primary c-color6 border-color6">Vazgeç</button>
                 
                     
                            <button [disabled]="!selectedStarValue "
                                style="width: 100%;height: 50px;font-size: 16px;font-weight: 600; border-radius: 8px;"
                                type="button" class="btn btn-primary bg-color1 c-color8" (click)="sendScore()">Gönder</button>
                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>