// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const firebaseConfig = {
//   apiKey: "AIzaSyAMbyJsrtP1qwZEp9cxjH2eD8Icc6gVR6s",
//   authDomain: "migrosevents.firebaseapp.com",
//   projectId: "migrosevents",
//   storageBucket: "migrosevents.appspot.com",
//   messagingSenderId: "1080236844037",
//   appId: "1:1080236844037:web:1b2462749539ca5a88686c",
//   measurementId: "G-TD7WER61WF"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCLeeMlZV9TAbLkfsFTk3YWOcFTtBusKCM",
  authDomain: "hale-sequence-105509.firebaseapp.com",
  databaseURL: "https://hale-sequence-105509.firebaseio.com",
  projectId: "hale-sequence-105509",
  storageBucket: "hale-sequence-105509.appspot.com",
  messagingSenderId: "919634677960",
  appId: "1:919634677960:web:d2e834717fc7b9256a15fd",
  measurementId: "G-SS4MRLGQCH"
};



// const apiURL = "https://mkolay-api-dev.migros.com.tr"
// const apiURL = "https://mkolay-api.migros.com.tr"
 const apiURL = "https://magaza-iphone.migros.com.tr/wsmkolay_test/wsgateway.aspx"
//const apiURL = "https://magaza-iphone.migros.com.tr/wsmkolay/wsgateway.aspx"
const encryptedKey = "2014technofacemigros7190"
const encryptedIV = "migr2019"

const technofaceKey = "AbqyzBPYNt2RB3HsjUXZQfFAYccUAmXn83BvH10UR0PgODWSVjq5KWYAfL4NZLJPy3U1tG529996UBw/1UWQZ5B2jOhrbpJAm0WLcdd+fAP0YgOMBXlBhRIw0T+HXSbAIWxaT8QBxWoJbpTCby3nNGVFmLMBdyQC0UyQnwEyPg1iaB9sBBu0XrId8u0BGqKhWkGeyqRWK0s+4xvZFYud5YePAjCb3kc6x+8PTcBOQOih6ssC+TZ3//sjrSfflAoCtHwsLHwo1sNtHTW+j2c5NXI8uywAGEj2U+HHswVbxM3W/l774Ux3hEqtw6p6M6XbbgpLlOyygfSInMmVtF/Uh/1j9LZ91gyyUbNYLUZJFLdsjgqfS1QQgdnnX9+XFum/4lo+ALVN6AbEwnB3EJYAsk+84R+20t5DIRpbGZuRiVtaqQHkl8/exXQzfiw8F5R0kZkskxRP5dejgnShkHyvWuTGp6hHG7J1YqywAQOmZQy6OaSGla40QwrkXxbWwgy4w6mLaofVqBg4IKVcTiylpL+mvBVevJCrmuuDRAZv6juqrzsUbzSXXH384Eeyvru7bGP4Egij83GQrDrh5bt/aHVzbcg8b2+aFK4hfC+AFCFOaFm1ReZ+7/gU46HJlTOoO73Np77nN3feXTA+bN4tpZHZHgZGrZYopF8qIhvxOvxHs0ceJk6+F1aHzGx7WvwV774wieVC5wIRszWzldM7Bx0pdlIa1XMYcWTsQRDpgDx+UEUj0yRqZD/ciJOGLXaBxsZN4WeKJGMMSv09+8HiFNUJqwFMrgyHuAfTzCKNAvRIq9LZIz0veH1+v8DrBVKrAiEzPb0qwBrB37ppTu2npWFQPM1WEFePlXXvK129xGz+Bg=="

const migrosKey = "AQliqZGBBdSsIjzCA0PHElk16WDBH5Anr1yDXI0pVx5yKocIfREyU9siUdLqP9T5dVua+Y9C4ki0G9N8bHl3I9FP2DX5e8Ag1XRlK4J+hzPcQQnuq3rqEV4f5gpPVaTMQQ9g5PFS3DTXCgYn6XuXQ6E6vqpcTG8nKh+oAN4WXjsuAI5BTRCuYAES8t/+GKLryLaEzdaYTgcGxSSMuhStHwwRivAiEzqdwdPefA4SyfHHNIypswu5pVgSgfL15eexDchd5OzN4/W0ZePrY2BSkdtW9FnVK3ugf8Feu/+xvIoM708VuutszFxce0HnfFu6FNNmwvBH9Mvws1lPwXbb2kvh5b1lb3vCKVj97LwamxQRhKHX1Q26nAs+BImtAJYHQMb3+1+CPXqEkDxOeq2JHN2x+e/uMm1vpNbmKox6CiHRVYA36UMlhwO4rCM/ytWTPkDaBZrZq3D6pPXLZRnrXj0bKXQvY7u/KjbsqKsQGPKJlI3cGZddXphqcmkRwnzuFZNaiWRRz6IWDRFUwdcKKtvsevAdKdko6TK9tSpLCoDlOD6wuBFG0AFkjm9UUTUtAXKQokVjDbRz2lJQZRHRcTCeH31XJnKbdRPA4cwLb/D/ppjTT7Vj5Hw9t4R2nzc/Jnsv2Z2loP2GHjoOSIqADojq8GYBIqrhXxDLg036HemmcO/oOnuCBopjwFU6EyMzSUCsCMQbfNtgIiFcnlT4xzxlUwDR+Bby3Y7PL8lfJuUr5M8i69XxQNByizW78jZmkr2EItnrLfIFpS7MuveKYqICP9h/3bOO9JPx3fwy6nxE6XZsHO6Tt4Wvm0WsOIKSXglACnXG4Ew9nQ8nmzUe686eF/2kFyuzfqk="

var scanditKey = technofaceKey

export const environment = {
  production: false,
  firebaseConfig,
  apiURL,
  encryptedKey,
  encryptedIV,
  scanditKey
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
