import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WelcomeComponent } from './welcome/welcome.component';
import { WelcomeWizardComponent } from './welcome-wizard/welcome-wizard.component';
import { StoreLocationCheckComponent } from './store-location-check/store-location-check.component';
import { LottieModule } from 'ngx-lottie';
import { StoreMapsComponent } from './store-maps/store-maps.component';
import { StoreDetailComponent } from './store-detail/store-detail.component';
import { SearchStoreComponent } from './search-store/search-store.component';
import { NearStoresComponent } from './near-stores/near-stores.component';
import { StoreComponent } from './store/store.component';
import { ScanAddProductComponent } from './scan-add-product/scan-add-product.component';
import { ScanditSdkModule } from 'scandit-sdk-angular';
import { ProductComponent } from './product/product.component';
import { ScanDeleteProductComponent } from './scan-delete-product/scan-delete-product.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { FooterComponent } from './layouts/footer/footer.component';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { BasketComponent } from './basket/basket.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ProfileComponent } from './profile/profile.component';
import { ShoppingHistoryComponent } from './shopping-history/shopping-history.component';
import { ShoppingHistoryDetailComponent } from './shopping-history-detail/shopping-history-detail.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SearchStoreQrComponent } from './search-store-qr/search-store-qr.component';
import localeTr from '@angular/common/locales/tr';
import { registerLocaleData } from '@angular/common';
import { JetkasaScanQrComponent } from './payment/jetkasa/jetkasa-scan-qr/jetkasa-scan-qr.component';
import { Interceptor } from './interceptors/interceptor';
import { PayWithCreditCardComponent } from './payment/credit-card/pay-with-credit-card/pay-with-credit-card.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { PayWithMoneyPayComponent } from './payment/money-pay/pay-with-money-pay/pay-with-money-pay.component';
import { MoneyPayPaymentOptionsComponent } from './payment/money-pay/money-pay-payment-options/money-pay-payment-options.component';
import { MoneyPayOtpVerificationComponent } from './payment/money-pay/money-pay-otp-verification/money-pay-otp-verification.component';
import { CodeInputModule } from 'angular-code-input';
import { ToastrModule } from 'ngx-toastr';
import { JetkasaSuccessComponent } from './payment/jetkasa/jetkasa-success/jetkasa-success.component';
import { CreditCardSuccessComponent } from './payment/credit-card/credit-card-success/credit-card-success.component';
import { CreditCardFailComponent } from './payment/credit-card/credit-card-fail/credit-card-fail.component';
import { OtpProcessComponent } from './otp-process/otp-process.component';
import { NgxMaskModule } from 'ngx-mask';
import { ClearCacheComponent } from './clear-cache/clear-cache.component';
import { PaymentOptionsComponent } from './payment/payment-options/payment-options.component';
import { MycardsComponent } from './mycards/mycards.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { AddProductSpecialOffersComponent } from './add-product-special-offers/add-product-special-offers.component';

registerLocaleData(localeTr, 'tr');

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

// tslint:disable-next-line:max-line-length
const licenseKey: string = environment.scanditKey;
const engineLocation: string = "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build"; // could also be e.g. "build"

const ngxUiLoaderConfig: NgxUiLoaderConfig = 
{
  fgsColor: "#65BAB0",
  bgsColor: "#65BAB0",
  pbColor: "#65BAB0",
  blur: 15,
  overlayColor: "rgba(255,255,255,1)",
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    WelcomeWizardComponent,
    StoreLocationCheckComponent,
    StoreMapsComponent,
    StoreDetailComponent,
    SearchStoreComponent,
    NearStoresComponent,
    StoreComponent,
    ScanAddProductComponent,
    ProductComponent,
    ScanDeleteProductComponent,
    FooterComponent,
    BasketComponent,
    ProfileComponent,
    ShoppingHistoryComponent,
    ShoppingHistoryDetailComponent,
    LoginComponent,
    SignupComponent,
    SearchStoreQrComponent,
    JetkasaScanQrComponent,
    PayWithCreditCardComponent,
    PayWithMoneyPayComponent,
    MoneyPayPaymentOptionsComponent,
    MoneyPayOtpVerificationComponent,
    JetkasaSuccessComponent,
    CreditCardSuccessComponent,
    CreditCardFailComponent,
    OtpProcessComponent,
    ClearCacheComponent,
    PaymentOptionsComponent,
    MycardsComponent,
    SpecialOffersComponent,
    AddProductSpecialOffersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    ScanditSdkModule.forRoot(licenseKey, { engineLocation, preloadEngine: false, preloadBlurryRecognition: false }),
    Ng2SearchPipeModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), 
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    QRCodeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    CodeInputModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
