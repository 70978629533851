import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../services/core.service';
declare var $
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  nearStore: any = [];
  storeID;
  storeIdLocal
  // cardNumber = localStorage.getItem('virtualOrCardId')
  cardNumber = localStorage.getItem("virtualOrCardId")
  basketID;
  productList = []
  userInfo: any

  migrosPinOptions: AnimationOptions = {
    path: '../../assets/img/migros-pin-new.json'
  };

  constructor(public coreService: CoreService, private route: ActivatedRoute, private router: Router, private toastrService: ToastrService) {
    this.storeID = this.route.snapshot.paramMap.get('storeID');
    // localStorage.setItem('storeId', this.storeID)
    this.storeIdLocal = localStorage.getItem('storeId')
  }

  async ngOnInit() {
    await this.searchNearStoresByLatLong()
    this.getProductList()
    this.userInfo = localStorage.getItem("userInfo")
    this.userInfo = JSON.parse(this.userInfo)

    localStorage.setItem('seenStoreID', this.storeID)
  }

  async searchNearStoresByLatLong() {
    var data = `act=magazadetay&magazano=${this.storeID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {

      this.nearStore = data["ResultObjects"][0]
      console.log(this.nearStore)

    }, (err => {
      console.log(err)
    }))
  }

  async getProductList() {
    //Product List
    this.basketID = localStorage.getItem('basketId')
    console.log(this.productList)
    if (this.basketID) {
      var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketID}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {

        this.productList = data["resultData"]["productList"]
        console.log(this.productList)
      })
    }
  }

  // generateCardNumber() {

  //   if (!localStorage.getItem("userInfo")) {
  //     console.log("girdi")

  //     var data = `act=mkolay_generatecardnumber`

  //     this.coreService.coreServiceFunc(data).subscribe(data => {
  //       console.log("vir",data)
  //       localStorage.setItem("virtualOrCardId", data["resultData"].cardNumber)

  //       this.createBasket()

  //     })

  //   }
  //   else {
  //     this.createBasket()
  //   }
  // }

  async createBasket() {
    if(this.storeIdLocal && this.storeID != this.storeIdLocal){
      localStorage.removeItem("basketId")
    }
    if (!localStorage.getItem("basketId")) {

      this.cardNumber = localStorage.getItem("virtualOrCardId")

      if (this.userInfo) {
        var data = `act=mkolay_createbasket&magazano=${this.storeID}&myhash=1231231233475638457&gsmId=${this.userInfo.gsmId}&cardId=${this.userInfo.cardId}&storeType=M`
      }
      else {
        var data = `act=mkolay_createbasket&magazano=${this.storeID}&myhash=1231231233475638457&storeType=M&virtualcardno=${this.cardNumber}`
      }

      this.coreService.coreServiceFunc(data).subscribe(data => {
        if (data["result"] == 0) {
          console.log(data["resultData"])
          localStorage.setItem('basketId', data["resultData"]["basketId"])
          if (data["resultData"]["basketId"] == null) { localStorage.setItem('basketId', "") }
          localStorage.setItem('jetQrCode', data["resultData"]["jetQrCode"])
          localStorage.setItem('basketDate', new Date().toString())
          localStorage.setItem('createBasketDate', Date.now().toString())
          localStorage.setItem('storeId', this.storeID)
          localStorage.setItem('storeName', data["resultData"]["storeName"])
          if(!this.userInfo && !localStorage.getItem('isShowedLoginModal')){
            $('#moneyLoginPopup').modal('show')
            localStorage.setItem('isShowedLoginModal', "true")
          }
          else {
            this.router.navigateByUrl(`/scan-add-product`);
          }
        }
        else if(data["result"] == 1) {
          this.toastrService.error("Hata", data["userMessage"])
          setTimeout(() => {
            localStorage.removeItem("storeName")
            localStorage.removeItem("basketId")
            localStorage.removeItem("userInfo")
            localStorage.removeItem("virtualOrCardId")
            localStorage.removeItem("basketDate")
            localStorage.removeItem("jetQrCode")
            localStorage.removeItem("createBasketDate")
            localStorage.removeItem('isShowedLoginModal')
            location.href = "/login"
          }, 1000);
        }
        
        
      })
    }
    else if (localStorage.getItem("basketId")) {
      if (localStorage.getItem("basketId") == "null" || localStorage.getItem("basketId") == undefined) {
        this.toastrService.error("Hata", "Sistemsel bir sorun çıktı. Sayfa yenileniyor. Tekrar deneyiniz.")
        
        setTimeout(() => {
          location.reload()
        }, 1000);
      }
      else if(this.userInfo){
        this.router.navigateByUrl(`/scan-add-product`);
      }
      else {
        if(!localStorage.getItem('isShowedLoginModal')){
          $('#moneyLoginPopup').modal('show')
        }
        else {
          this.router.navigateByUrl(`/scan-add-product`);
        }
        
      }
      
    }

  }

  moneyLoginPopup(){
    this.router.navigateByUrl(`/scan-add-product`);
  }

}
