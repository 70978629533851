import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

declare var $;

@Component({
  selector: 'app-pay-with-credit-card',
  templateUrl: './pay-with-credit-card.component.html',
  styleUrls: ['./pay-with-credit-card.component.css']
})
export class PayWithCreditCardComponent implements OnInit {

  qrData;
  basketId;
  storeId;
  userInfo:any
  currentDate = new Date().toISOString().split('.')[0];
  cardNumber = localStorage.getItem('virtualOrCardId')
  // cardNumber:any =""
  firebaseBasketData;
  firebasePaymentData;
  productDetails;
  totalPrice
  totalPriceTwo
  totalDiscount
  isAddedBasket
  step
  testURLLink
  paymentID;

  constructor(public coreService: CoreService, private firestore: AngularFirestore,private router:Router, private toastrService: ToastrService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    window.onpageshow = (event) => {
      if (event.persisted) {
        this.listenFirebase()
      }
  };

    this.basketId = localStorage.getItem('basketId')
    this.storeId = localStorage.getItem('storeId')
    this.userInfo = localStorage.getItem("userInfo")
    this.userInfo = JSON.parse(this.userInfo)
    this.qrData = `${this.basketId}_${this.currentDate}_${this.storeId}`
    //this.generateQRCode()
    this.listenFirebase()
    if(this.basketId){
      this.step = 2
      //this.step = 3
    }
    else{
      this.step = 1
    }

  }

  generateQRCode() {
    var data = `act=storegenerateqrcode&cardnumber=${this.cardNumber}&basketId=${this.basketId}&qrcode=${this.qrData}&myhash=1231231233475638457&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {
      console.log(data)
      this.listenFirebase()
      //this.getBasket()

    }, (err => {
      console.log(err)
    }))
  }

  listenFirebase() {
    this.firestore.collection('magaza').doc(`${this.basketId}`).valueChanges().subscribe(res => {
      this.firebaseBasketData = res
      console.log(res)
      if(!this.firebaseBasketData){
        this.generateQRCode()
      }
      else if(this.firebaseBasketData){
        this.getBasket()
      }
      

    })
  }

  getBasket() {
    var data = `act=mkolay_checkbasket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {


      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
      console.log(this.totalPrice.toString())
      this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      if (parseInt(this.productDetails.packageData.quantity) == 0) {
        this.isAddedBasket = "0"
      }
      else {
        this.isAddedBasket = "1"
      }
      if (this.firebaseBasketData.status == 1000) {
        //this.payWithCreditCard(this.totalPrice.toString())
        $('#addBagModal').modal('show')
      }
    }, (err => {
      console.log(err)
    }))
  }

  async payWithCreditCard(amount) {
    // cardId loginse
    // var data = `act=mkolay_ccpaymentlink&cardnumber=${this.userInfo.cardId}&basketid=${this.basketId}&amount=${amount}&myhash=1231231233475638457&datetoken=29092022120911650&storetype=M`

    var data = `act=mkolay_ccpaymentlink_crm&basketid=${this.basketId}&cardid=${this.userInfo.cardId}&gsmid=${this.userInfo.gsmId}`

    this.coreService.coreServiceFunc(data).subscribe(async data => {
      console.log(data)
      if(data["result"] == 0){
        // localStorage.setItem('transactionId', data["response"]["transactionId"])
        // if (data["response"]["paymentPage"]) {
        //   //location.href = data["response"]["paymentPage"]
        // }
        this.paymentID = data["resultData"]["paymentId"]
        this.listenPaymentFirebase(this.paymentID)
      }
      else {
        this.toastrService.error(data["resultMessage"], "Hata")
      }
      
    }, (err => {
      console.log(err)
    }))
  }

  listenPaymentFirebase(paymentId){
    this.firestore.collection('payment').doc(`${paymentId}`).valueChanges().subscribe(res => {
      this.firebasePaymentData = res
      console.log(res)
      if(this.firebasePaymentData.status == 5000){
        this.step = 3
        this.testURLLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.firebasePaymentData.paymentUrl);
      }
      if(this.firebasePaymentData.status == 5001){
        console.log("SUCCESS")
        console.log(res)
        location.href = `payment/credit-card/success?hash=${res["hash"]}&paymentid=${res["paymentId"]}&transactionid=${res["transactionId"]}`
      }
      else if(this.firebasePaymentData.status == -5001){
        console.log("FAILED")
        location.href = "payment/credit-card/fail"
      }
      

    })
  }

  async increaseBagToBasket() {

    var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&scannedBarcode=${this.productDetails.packageData.barcode}&originalBarcode=${this.productDetails.packageData.barcode}&productCode=${this.productDetails.packageData.productcode}&quantity=${parseInt(this.productDetails.packageData.quantity) + 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(data => {


      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
      console.log(this.totalPrice.toString())
      this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      if (parseInt(this.productDetails.packageData.quantity) == 0) {
        this.isAddedBasket = "0"
      }
      else {
        this.isAddedBasket = "1"
      }
      console.log(data)
    })

  }

  async decreaseBagToBasket() {

    if (parseInt(this.productDetails.packageData.quantity) >= 1) {
      var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&scannedBarcode=${this.productDetails.packageData.barcode}&originalBarcode=${this.productDetails.packageData.barcode}&productCode=${this.productDetails.packageData.productcode}&quantity=${parseInt(this.productDetails.packageData.quantity) - 1}&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

      this.coreService.coreServiceFunc(data).subscribe(data => {


        this.productDetails = data["resultData"]
        console.log(this.productDetails)
        this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
        console.log(this.totalPrice.toString())
        this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
        this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
        if (parseInt(this.productDetails.packageData.quantity) == 0) {
          this.isAddedBasket = "0"
        }
        else {
          this.isAddedBasket = "1"
        }
        console.log(data)
      })
    }

  }

  async setZeroBagToBasket() {


    var data = `act=mkolay_add2basket&cardnumber=${this.cardNumber}&basketId=${this.basketId}&scannedBarcode=${this.productDetails.packageData.barcode}&originalBarcode=${this.productDetails.packageData.barcode}&productCode=${this.productDetails.packageData.productcode}&quantity=0&myhash=1231231233475638457&uniqueid=59CC3AF0-9AED-4AD9-BE28-33B9022B55A0&datetoken=29092022120911650`

    this.coreService.coreServiceFunc(data).subscribe(async data => {


      this.productDetails = data["resultData"]
      console.log(this.productDetails)
      this.totalPrice = parseFloat(data["resultData"]["totalAmount"]) * 100
      console.log(this.totalPrice.toString())
      this.totalPriceTwo = parseFloat(data["resultData"]["totalAmount"])
      this.totalDiscount = parseFloat(data["resultData"]["totalDiscount"])
      if (parseInt(this.productDetails.packageData.quantity) == 0) {
        this.isAddedBasket = "0"
      }
      else {
        this.isAddedBasket = "1"
      }
      $('#addBagModal').modal('hide')
      await this.payWithCreditCard(this.totalPrice.toString())
      console.log(data)
    })

  }

  async goCreditCardSelectionPage() {
    $('#addBagModal').modal('hide')
    await this.payWithCreditCard(this.totalPrice.toString())
  }

  onNavigatePaymentOptions() {

    this.router.navigateByUrl('/payment/payment-options')

  }

}
